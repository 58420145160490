import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import VueCookies from 'vue-cookies';
import { kisokmodule } from './kisok/kisokmodule';
import { playermodule } from './player/playermodule';
import { couponmodule } from './coupon/couponmodule';
import { cesweathermodule } from './cesweather/cesweathermodule';
import { cescurationmodule } from './cescuration/cescurationmodule';
import { cesuserinfomodule } from './cesuserinfo/cesuserinfomodule';
import { cesplayermodule } from './cesplayer/cesplayermodule';
import { cesposmodule } from './cespos/cesposmodule';
import { seongdongplayermodule } from './seongdongplayer/seongdongplayermodule';
import { curationmodule } from './curation/curationmodule';
import { spacebuildingmodule } from './spacebuilding/spacebuildingmodule';
import { experiencemodule } from './experience/experiencemodule';
import { mediaSessionObject } from '../assets/js/mediaSession';
import { getSpaceName } from '../utils/Utils';
Vue.use(Vuex);
const baseImgUrl = process.env.VUE_APP_BASE_URL;
export default new Vuex.Store({
  state: {
    isAppOpen: false,
    fromMobile: localStorage.getItem('fromMobile') !== 'Y',
    hls: undefined,
    likeInfo: {
      like: null,
      unlike: null
    },
    historyMusicId: null,
    eventNewTag: false,
    preInfo: {
      weather: '',
      times: ''
    },
    currentInfo: {
      weather: '',
      times: ''
    },
    noticeId: '',
    eventId: '',
    noticeCount: 0,
    isApp: '',
    spaceNm: null,
    email: '',
    spaceId: '',
    autoPlayInfo: {
      startHours: '',
      startMinutes: '',
      endHours: '',
      endMinutes: '',
      week: [],
      useAt: ''
    },
    musicList: [
      {
        src: null,
        artist: null,
        musicNm: null,
        albumImg: null
      }
    ],
    route: null,
    currentMusicIdx: 0,
    playTime: '00:00',
    totDuration: '00:00',
    playNav: 20,
    isMute: false,
    volume: 0.5,
    preVolume: 0,
    pauseClickValue: '',
    countMusicPlayInPause: 0,
    isPlay: '',
    deviceToken: '',
    isSpaceConnection: false,
    sendMusicInfo: {
      nextFlag: '',
      auto: false
    },
    signupCouponAt: 'N',
    findChannel: 'N',
    division: '',
    updateCurationForBuilding: {
      spaceId: '',
      isChange: false
    },
    spaceInfo: '',
    popupModalStatus: true,
    counter: 0,
    spacePayAt: null,
    trialStatus: false,
    installablePlayer: false,
    stompClient: null,
    numRetries: 0, // 웹소켓 연결 재시도 횟수
    channelId: '',
    channelUrl: null,
    channelMusicInfo: {
      prevAlbumImg: '',
      prevArtist: '',
      prevMusicId: '',
      prevMusicNm: '',
      albumImg: '',
      artist: '',
      musicId: '',
      musicNm: '',
      nextAlbumImg: '',
      nextArtist: '',
      nextMusicId: '',
      nextMusicNm: ''
    },
    musicInfoInterval: -1,
    myFavoriteList: [],
    myChannelYn: true,
    isBrandMusic: false, // 브랜드 음원 여부
    isBrandMusicPlay: false, // 브랜드 음원의 재생 여부
    brandMusicInfo: null,
    selectWeatherAndTime: {
      weather: null,
      times: null
    },
    dummyChannelInfo: null,
    curationTemp: {
      weather: null,
      times: null,
      mood: null,
      domesticRate: null,
      genre: [],
      period: [],
      emotion: []
    },
    selectChannelInfo: null,
    brandhls: undefined,
    brandPlayerList: [],
    brandActiveIdx: -1,
    carCurationModal: false,
    brandMusicMute: false,
    noticeCloseList: [],
    limitList: ['user_004699', 'user_000236']
  },
  getters: {
    getIsAppOpen (state) {
      return state.isAppOpen;
    },
    getRoute (state) {
      return state.route;
    },
    getSpaceNm (state) {
      return state.spaceNm;
    },
    getLikeInfo (state) {
      return state.likeInfo;
    },
    getHls (state) {
      return state.hls;
    },
    isLogin (state) {
      return state.email !== '';
    },
    getCarCurationModal (state) {
      return state.carCurationModal;
    },
    gettersIsPlay (state) {
      if (state.isPlay === '' || state.isPlay === true) {
        return true;
      }
    },
    gettersIsMute (state) {
      return state.isMute;
    },
    gettersIsSpaceConnection (state) {
      return state.isSpaceConnection;
    },
    gettersNoticeList (state) {
      return state.noticeCloseList;
    },
    gettersNoticeId (state) {
      return state.noticeId;
    },
    gettersCurationCssClass (state) {
      let cssClass = '';
      if (state.currentInfo.times === '아침') {
        cssClass = 'morning';
      } else if (state.currentInfo.times === '낮') {
        cssClass = 'afternoon';
      } else if (state.currentInfo.times === '저녁') {
        cssClass = 'dinner';
      } else {
        cssClass = 'night';
      }
      return cssClass;
    },
    isKisok (state) {
      const userId = VueCookies.get('userId');
      const findIdx = state.limitList.findIndex(item => item === userId);
      if (findIdx > -1) {
        return true;
      }
      return false;
    },
    gettersChannelUrl (state) {
      return state.channelUrl;
    },
    gettersChannelId (state) {
      return state.channelId;
    },
    gettersChannelMusicInfo (state) {
      return state.channelMusicInfo;
    },
    gettersFromMobile (state) {
      return state.fromMobile;
    },
    gettersIsBrandMusic (state) {
      return state.isBrandMusic;
    },
    gettersBrandActiveIndex (state) {
      return state.brandActiveIdx;
    },
    gettersBrandMusicInfo (state) {
      return state.brandMusicInfo;
    }
  },
  mutations: {
    setIsAppOpen (state, val) {
      state.isAppOpen = val;
    },
    setFromMobile (state, val) {
      if (val === false) {
        localStorage.setItem('fromMobile', 'Y');
      }
      state.fromMobile = val;
    },
    setSelectWeatherAndTime (state, val) {
      state.selectWeatherAndTime = val;
    },
    setBrandMusicInfo (state, val) {
      state.brandMusicInfo = val;
    },
    setIsBrandMusic (state, val) {
      state.isBrandMusic = val;
    },
    setIsBrandMusicPlay (state, val) {
      state.isBrandMusicPlay = val;
    },
    setChannelMusicInfo (state, val) {
      // 미디어세션을 통해 음원 세팅
      if (val && Object.keys(val).includes('albumImg')) {
        val.albumImg = `${baseImgUrl}${val.albumImg}`;
      }
      if (val) {
        val.spaceName = getSpaceName();
      }
      mediaSessionObject.setMetaData(val);
      state.channelMusicInfo = val;
    },
    setMusicInfoInterval (state, val) {
      state.musicInfoInterval = val;
    },
    setNumRetries (state, val) {
      state.numRetries = val;
    },
    setChannelId (state, val) {
      state.channelId = val;
    },
    setChannelUrl (state, val) {
      state.channelUrl = val;
    },
    setStompClient (state, val) {
      state.stompClient = val;
    },
    setInstallablePlayer (state, val) {
      state.installablePlayer = val;
    },
    setTrialStatus (state, val) {
      state.trialStatus = val;
    },
    setSpacePayAt (state, val) {
      state.spacePayAt = val;
    },
    setSpaceInfo (state, spaceInfo) {
      state.spaceInfo = spaceInfo;
    },
    setUpdateCurationForBuilding (state, updateCurationForBuilding) {
      state.updateCurationForBuilding = updateCurationForBuilding;
    },
    setDivision (state, division) {
      state.division = division;
    },
    setFindChannel (state, findChannel) {
      state.findChannel = findChannel;
    },
    setCountMusicPlayInPause (state, countMusicPlayInPause) {
      state.countMusicPlayInPause = countMusicPlayInPause;
    },
    setPauseClickValue (state, pauseClickValue) {
      state.pauseClickValue = pauseClickValue;
    },
    setSignupCouponAt (state, signupCouponAt) {
      state.signupCouponAt = signupCouponAt;
    },
    setSendMusicInfo (state, musicInfo) {
      state.sendMusicInfo = musicInfo;
    },
    setSpaceConnection (state, isSpaceConnection) {
      state.isSpaceConnection = isSpaceConnection;
    },
    setLikeInfo (state, likeInfo) {
      state.likeInfo = likeInfo;
    },
    setHistoryMusicId (state, historyMusicId) {
      state.historyMusicId = historyMusicId;
    },
    setEventInfo (state, eventNewTag) {
      state.eventNewTag = eventNewTag;
    },
    setNoticeId (state, noticeId) {
      state.noticeId = noticeId;
    },
    setEventId (state, eventId) {
      state.eventId = eventId;
    },
    setPreInfo (state, preInfo) {
      state.preInfo = preInfo;
    },
    setCurrentInfo (state, currentInfo) {
      state.currentInfo = currentInfo;
    },
    setNoticeCount (state, noticeCount) {
      state.noticeCount = noticeCount;
    },
    setCounter (state, counter) {
      state.counter = counter;
    },
    setDeviceToken (state, deviceToken) {
      state.deviceToken = deviceToken;
    },
    setAutoPlay (state, autoPlayInfo) {
      state.autoPlayInfo = autoPlayInfo;
    },
    setIsApp (state, isApp) {
      state.isApp = isApp;
    },
    setCurrentMusicIdx (state, currentMusicIdx) {
      state.currentMusicIdx = currentMusicIdx;
    },
    setSpaceNm (state, spaceNm) {
      state.spaceNm = spaceNm;
    },
    setEmail (state, email) {
      state.email = email;
    },
    setSpaceId (state, spaceId) {
      state.spaceId = spaceId;
    },
    setMusicList (state, musicList) {
      state.musicList = musicList;
    },
    setPlayTime (state, playTime) {
      state.playTime = playTime;
    },
    setTotDuration (state, totDuration) {
      state.totDuration = totDuration;
    },
    setPlayNav (state, playNav) {
      state.playNav = playNav;
    },
    setMute (state, isMute) {
      state.isMute = isMute;
    },
    setVolume (state, volume) {
      state.volume = volume;
    },
    setPreVolume (state, preVolume) {
      state.preVolume = preVolume;
    },
    setPlay (state, isPlay) {
      state.isPlay = isPlay;
    },
    setMyFavoriteList (state, favoriteList) {
      state.myFavoriteList = favoriteList;
    },
    setMyChannelYn (state, myChannelYn) {
      state.myChannelYn = myChannelYn;
    },
    setDummyChannelInfo (state, channel) {
      state.dummyChannelInfo = channel;
    },
    setCurationTemp (state, curationTemp) {
      state.curationTemp = curationTemp;
    },
    setSelectChannelInfo (state, channelInfo) {
      state.selectChannelInfo = channelInfo;
    },
    setHls (state, val) {
      state.hls = val;
    },
    setBrandHls (state, val) {
      state.brandhls = val;
    },
    setBrandPlayerList (state, val) {
      state.brandPlayerList = [...state.brandPlayerList, val];
    },
    setClearBrandPlayerList (state, val) {
      state.brandPlayerList = val;
    },
    setBrandActiveIdx (state, val) {
      state.brandActiveIdx = val;
    },
    setCarCurationModal (state, val) {
      state.carCurationModal = val;
    },
    setRoute (state, val) {
      state.route = val;
      VueCookies.set('route', val);
    },
    initData (state) {
      state.noticeId = '';
      state.eventId = '';
      state.isApp = '';
      state.spaceNm = null;
      state.email = '';
      state.spaceId = '';
      state.autoPlayInfo = {
        startHours: '',
        startMinutes: '',
        endHours: '',
        endMinutes: '',
        week: [],
        useAt: ''
      };
      state.musicList = [
        {
          src: null,
          artist: null,
          musicNm: null,
          albumImg: null
        }
      ];
      state.currentMusicIdx = 0;
      state.playTime = '00:00';
      state.totDuration = '00:00';
      state.playNav = 20;
      state.isMute = false;
      state.volume = 0.5;
      state.isPlay = '';
      state.pauseClickValue = '';
      state.countMusicPlayInPause = 0;
      state.deviceToken = '';
      state.noticeCount = 0;
      state.currentInfo = {
        weather: '',
        times: ''
      };
      state.preInfo = {
        weather: '',
        times: ''
      };
      state.eventNewTag = false;
      state.historyMusicId = null;
      state.likeInfo = {
        like: null,
        unlike: null
      };
      state.isSpaceConnection = false;
      state.sendMusicInfo = {
        nextFlag: '',
        auto: false
      };
      state.signupCouponAt = 'N';
      state.FindChannel = 'N';
      state.division = '';
      state.updateCurationForBuilding = {
        spaceId: '',
        isChange: false
      };
      state.spaceInfo = '';
      state.popupModalStatus = true;
      state.spacePayAt = null;
      state.trialStatus = false;
      state.installablePlayer = false;
      state.stompClient = null;
      state.numRetries = 0;
      state.channelId = '';
      state.channelUrl = null;
      state.channelMusicInfo = null;
      state.musicInfoInterval = -1;
      state.isBrandMusic = false;
      state.isBrandMusicPlay = false;
      state.brandMusicInfo = null;
      state.selectWeatherAndTime = {
        weather: null,
        times: null
      };
      state.curationTemp = {
        weather: null,
        times: null,
        mood: null,
        domesticRate: null,
        genre: [],
        period: [],
        emotion: []
      };
      state.selectChannelInfo = null;
      state.dummyChannelInfo = null;
    },
    setBrandMusicMute (state, val) {
      state.brandMusicMute = val;
    },
    addNoticeList (state, item) {
      const findIndex = state.noticeCloseList.findIndex(findItem => findItem.noticeId === item.commonNoticeId);

      if (findIndex < 0) {
        const addItem = {
          noticeId: item.commonNoticeId,
          isVisible: 'N'
        };
        state.noticeCloseList = [...state.noticeCloseList, addItem];
      } else {
        state.noticeCloseList[findIndex].isVisible = 'N';
      }
    }
  },
  actions: {},
  modules: {
    kisok: kisokmodule,
    player: playermodule,
    coupon: couponmodule,
    cesweather: cesweathermodule,
    cescuration: cescurationmodule,
    cesuserinfo: cesuserinfomodule,
    cesplayer: cesplayermodule,
    cespos: cesposmodule,
    seongdongplayer: seongdongplayermodule,
    curation: curationmodule,
    spacebuilding: spacebuildingmodule,
    experience: experiencemodule
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: [
        'email',
        'isMute',
        'volume',
        'preVolume',
        'eventId',
        'noticeId',
        'signupCouponAt',
        'division',
        'spaceInfo',
        'popupModalStatus',
        'spacePayAt',
        'isApp',
        'trialStatus',
        'installablePlayer',
        'stompClient',
        'myFavoriteList',
        'myChannelYn',
        'selectWeatherAndTime',
        'dummyChannelInfo',
        'curationTemp',
        'selectChannelInfo',
        'channelMusicInfo'
      ]
    })
  ]
});
