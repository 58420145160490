import { selectFavoriteMusicList, updateFavoriteMusicListForZeroOne, getChannelInfo } from '@/service/api/playerApi';
import { createChannel, joinRecomChannel } from '@/service/api/webcastApi';
import VueCookies from 'vue-cookies';
import root from '../index';
import { cesposmodule } from '../cespos/cesposmodule';
import { updateCurationAllTemp, updateConfirmCuration, postRandomPreferMusic } from '@/service/api/profileApi';
import { videoObject } from '@/assets/js/videoPlayer';
import { cloneDeep } from 'lodash';
import { playermodule } from '../player/playermodule';

const cesplayermodule = {
  namespaced: true,
  state: {
    selectColor: '',
    selectInterior: [],
    selectMood: [],
    fullLoading: false,
    blurLoading: false,
    blurText: '',
    activeDummyChannel: -1,
    activeCesPlayerChannel: 0,
    originalChannelInfo: {},
    volume: 0.5,
    recommPosDummyList: [
      {
        channelId: 'DummyChannel1',
        channelType: 'D',
        sector: '음식점1',
        spaceId: 'DummySpace1',
        spaceNm: "Karls' jr.",
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel2',
        channelType: 'D',
        sector: '음식점2',
        spaceId: 'DummySpace2',
        spaceNm: 'Four Guys',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel3',
        channelType: 'D',
        sector: '음식점1',
        spaceId: 'DummySpace3',
        spaceNm: "Hardy's Burger",
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel4',
        channelType: 'D',
        sector: '음식점2',
        spaceId: 'DummySpace4',
        spaceNm: 'Popeyas',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel5',
        channelType: 'D',
        sector: '음식점1',
        spaceId: 'DummySpace5',
        spaceNm: "John Jimmy's",
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel6',
        channelType: 'D',
        sector: '음식점2',
        spaceId: 'DummySpace6',
        spaceNm: 'Delly King',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel7',
        channelType: 'D',
        sector: '음식점1',
        spaceId: 'DummySpace7',
        spaceNm: "Anna's",
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel8',
        channelType: 'D',
        sector: '음식점2',
        spaceId: 'DummySpace7',
        spaceNm: 'Pizza Hot',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel9',
        channelType: 'D',
        sector: '음식점1',
        spaceId: 'Taco Ring',
        spaceNm: "Anna's",
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel10',
        channelType: 'D',
        sector: '음식점2',
        spaceId: 'Kings Burger',
        spaceNm: "Anna's",
        spaceType: 'Store'
      }
    ],
    recommDummyList: [
      {
        channelId: 'DummyChannel1',
        channelType: 'D',
        sector: '카페1',
        spaceId: 'DummySpace1',
        spaceNm: '80 Degrees',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel2',
        channelType: 'D',
        sector: '카페2',
        spaceId: 'DummySpace2',
        spaceNm: 'Cafe Lio',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel3',
        channelType: 'D',
        sector: '카페1',
        spaceId: 'DummySpace3',
        spaceNm: 'Cafe4U',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel4',
        channelType: 'D',
        sector: '카페2',
        spaceId: 'DummySpace4',
        spaceNm: 'Soft Bean Coffee',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel5',
        channelType: 'D',
        sector: '카페1',
        spaceId: 'DummySpace5',
        spaceNm: 'Espresso Delight',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel6',
        channelType: 'D',
        sector: '카페2',
        spaceId: 'DummySpace6',
        spaceNm: 'Gloria Beans Coffee',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel7',
        channelType: 'D',
        sector: '카페1',
        spaceId: 'DummySpace7',
        spaceNm: 'Cafe Bean',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel8',
        channelType: 'D',
        sector: '카페2',
        spaceId: 'DummySpace7',
        spaceNm: 'Red Bottle Coffee',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel9',
        channelType: 'D',
        sector: '카페1',
        spaceId: 'DummySpace7',
        spaceNm: 'Kosta Coffee',
        spaceType: 'Store'
      },
      {
        channelId: 'DummyChannel10',
        channelType: 'D',
        sector: '카페2',
        spaceId: 'DummySpace7',
        spaceNm: "Seattle's First Coffee",
        spaceType: 'Store'
      }
    ],
    recomList: [],
    channelInfo: {},
    isChangeChannel: false,
    selectChannel: {},
    isLogoutModal: false,
    isCesPosCuration: false,
    myFavoriteList: [],
    experienceInterval: -1,
    experienceModal: false
  },
  getters: {
    getSelectColor (state) {
      return state.selectColor;
    },
    getSelectInterior (state) {
      return state.selectInterior;
    },
    getVolume (state) {
      return state.volume;
    },
    getSelectMood (state) {
      return state.selectMood;
    },
    getOriginalChannelInfo (state) {
      return state.originalChannelInfo;
    },
    getActiveDummyChannel (state) {
      return state.activeDummyChannel;
    },
    getActiveCesPlayerChannel (state) {
      return state.activeCesPlayerChannel;
    },
    getFullLoading (state) {
      return state.fullLoading;
    },
    getBlurLoading (state) {
      return state.blurLoading;
    },
    getDummyList (state) {
      return state.recommDummyList;
    },
    getPosDummyList (state) {
      return state.recommPosDummyList;
    },
    getRecomList (state) {
      return state.recomList;
    },
    getChannelInfo (state) {
      return state.channelInfo;
    },
    getIsChangeChannel (state) {
      return state.isChangeChannel;
    },
    getSelectChannel (state) {
      return state.selectChannel;
    },
    getIsLogoutModal (state) {
      return state.isLogoutModal;
    },
    getMyFavoriteList (state) {
      return state.myFavoriteList;
    },
    getIsCesPosCuration (state) {
      return state.isCesPosCuration;
    },
    getBlurText (state) {
      return state.blurText;
    },
    getExperienceInterval (state) {
      return state.experienceInterval;
    },
    getExperienceModal (state) {
      return state.experienceModal;
    }
  },
  mutations: {
    setSelectColor (state, payload) {
      state.selectColor = payload;
    },
    setSelectInterior (state, payload) {
      state.selectInterior = payload;
    },
    setVolume (state, payload) {
      state.volume = payload;
    },
    setSelectMood (state, payload) {
      // localStorage.setItem("selectMood", JSON.stringify(payload));

      state.selectMood = payload;
    },
    setBlurText (state, payload) {
      state.blurText = payload;
    },
    setFullLoading (state, payload) {
      state.fullLoading = payload;
    },
    setBlurLoading (state, payload) {
      state.blurLoading = payload;
    },
    setRecommDummyList (state, payload) {
      state.recommDummyList = payload;
    },
    setRecomList (state, payload = []) {
      if (payload.length > 0) {
        payload.forEach((item, index) => {
          item.id = `channel${index + 1}`;
        });
      }
      state.recomList = payload;
    },
    setChannelInfo (state, payload) {
      state.channelInfo = payload;
    },
    setIsChangeChannel (state, payload) {
      state.isChangeChannel = payload;
    },
    setSelectChannel (state, payload) {
      state.selectChannel = payload;
    },
    setIsLogoutModal (state, payload) {
      state.isLogoutModal = payload;
    },
    setMyFavoriteList (state, payload) {
      state.myFavoriteList = payload;
    },
    setIsCesPosCuration (state, payload) {
      state.isCesPosCuration = payload;
    },
    setActiveDummyChannel (state, payload) {
      state.activeDummyChannel = payload;
    },
    setOriginalChannelInfo (state, payload) {
      state.originalChannelInfo = payload;
    },
    setExperienceInterval (state) {
      // let count = 0;
      clearInterval(state.experienceInterval);
      const intervalId = setInterval(() => {
        // eslint-disable-next-line no-undef
        count++;
        // if (count === 60 * 5) {
        // state.experienceModal = true;
        // }
      }, 1000);

      state.experienceInterval = intervalId;
    },
    setExperienceModal (state, payload) {
      state.experienceModal = payload;
    },
    setActiveCesPlayerChannel (state, payload) {
      state.activeCesPlayerChannel = payload;
    }
  },
  actions: {
    async changeCuration ({ commit, getters }, params) {
      try {
        cesposmodule.mutations.setIsCesPosCuration(cesposmodule.state, false);
        commit('setBlurLoading', true);
        commit('setBlurText', 'poschangecuration');
        const { data } = await updateCurationAllTemp(params.curationInfo);
        const { resultCd } = data;
        if (resultCd === '0000') {
          const { data: confirmData } = await updateConfirmCuration(params.curationInfo.spaceId);
          const { resultCd: confirmResultCd } = confirmData;
          if (confirmResultCd === '0000') {
            const aToken = VueCookies.get('aToken');
            const headers = { 'X-AUTH-TOKEN': aToken };
            const { data: preferData } = await postRandomPreferMusic(params.curationInfo.spaceId);
            const { resultCd: preferCd } = preferData;
            if (preferCd === '0000') {
              const { data: createData } = await createChannel({
                userId: params.userId,
                spaceId: params.curationInfo.spaceId,
                headers
              });
              const { resultCd: createResultCd, result: createResult } = createData;

              if (createResultCd === '0000') {
                if (root.state.channelUrl !== createResult.url) {
                  root.commit('setChannelId', createResult.channelId);
                  root.commit('setChannelUrl', createResult.url);
                  root.commit('player/setOriginalUrl', createResult.url);
                  getChannelInfo(createResult.channelId)
                    .then(res => {
                      const { resultCd: channelResultCd, result: channelResult } = res.data;
                      if (channelResultCd === '0000') {
                        commit('setChannelInfo', channelResult);
                        //
                        console.log('getters.getActiveDummyChannel : ', getters.getActiveDummyChannel);
                        const next1 = getters.getActiveDummyChannel + 1;
                        const next2 = next1 + 1 > getters.getPosDummyList.length - 1 ? 0 : next1 + 1;
                        // const nextActiveDummyChannel = getters.getActiveDummyChannel >= getters.getDummyList.length - 1 ? 0 : getters.getActiveDummyChannel + 1;
                        commit('setActiveDummyChannel', next2);

                        const dummyChannel1 = getters.getPosDummyList[next1];
                        const dummyChannel2 = getters.getPosDummyList[next2];
                        const cloneRecommList = cloneDeep(getters.getRecomList);
                        const filterDummy = cloneRecommList.filter(item => item.channelType !== 'D');

                        commit('setRecomList', [...filterDummy, dummyChannel1, dummyChannel2]);
                      } else {
                        commit('setChannelInfo', {});
                      }
                    })
                    .catch(() => {
                      commit('setChannelInfo', {});
                    });
                } else {
                  videoObject.onPause();
                  videoObject.playerMusic(createResult.url);
                  videoObject.startLoad(-1);
                  const promise = videoObject.onPlay();
                  if (promise !== undefined) {
                    promise
                      .then(() => {
                        console.log('success');
                      })
                      .catch(err => {
                        console.log(err);
                      })
                      .finally(() => {
                        commit('setBlurLoading', false);
                      });
                  }
                }
              }
            } else {
              throw new Error('updateCuration error');
            }
          }
        }
      } catch (error) {
        console.error('updateCuration error : ', error);
        commit('setBlurLoading', false);
      }
    },
    saveMyFavoriteList ({ commit }, spaceId) {
      selectFavoriteMusicList(spaceId)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            commit('setMyFavoriteList', result);
          } else {
            commit('setMyFavoriteList', []);
          }
        })
        .catch(err => {
          console.log('saveMyFavoriteList error : ', err);
        });
    },
    async changeChannel ({ commit, state }, item) {
      try {
        const aToken = VueCookies.get('aToken');
        const headers = { 'X-AUTH-TOKEN': aToken };
        // const joinMusicList = state.myFavoriteList
        const musicList = state.myFavoriteList.map(item => item.musicId).join(',');
        // 선택된 채널 확인후 myChannelYn 전달
        const channelInfo = state.channelInfo;
        const myChannelYn = channelInfo.channelType !== 'D';
        const { data: updateMusicData } = await updateFavoriteMusicListForZeroOne(
          item.userId,
          item.spaceId,
          musicList,
          myChannelYn
        );
        const { resultCd: updateResultCd } = updateMusicData;
        if (updateResultCd === '0000') {
          const { data: createResult } = await createChannel({ userId: item.userId, spaceId: item.spaceId, headers });

          const { resultCd, result } = createResult;
          if (resultCd === '0000') {
            root.commit('setChannelId', result.channelId);
            root.commit('setChannelUrl', result.url);
            root.commit('player/setOriginalUrl', result.url);

            if (myChannelYn) {
              const { data: selectFavoriteResult } = await selectFavoriteMusicList(item.spaceId);
              const { resultCd: favoriteResultCd, result: favoriteResult } = selectFavoriteResult;

              if (favoriteResultCd === '0000') {
                commit('setMyFavoriteList', favoriteResult);
                return true;
              } else {
                commit('setMyFavoriteList', []);
                return false;
              }
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      } catch (error) {
        return new Error(error);
      }
    },
    async posChangeChannel ({ commit, getters, dispatch }) {
      commit('setBlurLoading', true);
      commit('setBlurText', 'joinchannel');
      commit('setChannelInfo', getters.getSelectChannel);

      const userId = VueCookies.get('userId');
      const spaceId = VueCookies.get('cesposSpaceId');
      const channelId = root.state.channelId;

      const { channelId: dstChannelId, channelType } = getters.getSelectChannel;

      if (channelType === 'R' || channelType === 'T') {
        try {
          const joinChannelResult = await joinRecomChannel(userId, spaceId, channelId, dstChannelId);
          const { result } = joinChannelResult.data;
          const { channelId: resultChannelId, url } = result;
          root.commit('setChannelId', resultChannelId);
          root.commit('setChannelUrl', url);
          if (channelType === 'R') {
            const intervalId = setInterval(() => {
              fetch(playermodule.getters.getOriginalUrl);
              // eslint-disable-next-line no-undef
            }, standard);
            playermodule.commit('setOriginalChannelInterval', intervalId);
          }
        } catch (error) {
          console.error('change channel error : ', error);
          commit('setBlurLoading', false);
        }
      } else {
        try {
          await dispatch('changeChannel', {
            userId,
            spaceId,
            channelId
          });

          clearInterval(playermodule.getters.getOriginalChannelInterVal);
        } catch (error) {
          console.log(error);
          commit('setBlurLoading', false);
        }
      }
    }
  }
};
export { cesplayermodule };
