const cesuserinfomodule = {
  namespaced: true,
  state: {
    limituser: ["ces2024@gmail.com", "ces2024a@gmail.com"]
  },
  getters: {
    isCesUser: (state) => val => {
      if (state.limituser.includes(val)) {
        return true;
      }
      return false;
    }
  },
  mutations: {},
  actions: {}
};

export { cesuserinfomodule };
