import { postRandomPreferMusic, getSpaceCurationInfo, selectCurationInfoAll } from '@/service/api/profileApi';
import {
  selectSimilarChannels,
  selectBookmarkChannels,
  getChannelInfo,
  getChannelMusicInfo,
  insertBookmarkChannel,
  deleteBookmarkChannel,
  checkLike
} from '@/service/api/playerApi';
import { getCookie, setBg, isNestedAutoPlayMusic, isNestedBrandMusic } from '@/utils/Utils';
import {
  insertUserBookmarkChannel,
  selectUserBookmarkChannelList,
  deleteUserBookmarkChannel,
  getNonmemberFixedChannelList,
  getNonmemberPopularChannelList,
  getNonmemberChannelMusicInfo,
  userFixedChannel,
  getUserName
} from '@/service/api/userApi';
import { createChannel, joinRecomChannel, joinRecomChannelUser } from '@/service/api/webcastApi';
import { videoObject } from '@/assets/js/videoPlayer';
import store from '@/store';
import { cloneDeep, clone } from 'lodash';
import { experiencemodule } from '../experience/experiencemodule';
import { mediaSessionObject } from '@/assets/js/mediaSession';
import VueCrontab from 'vue-crontab';
import Hls from 'hls.js';

const baseImgUrl = 'https://www.aplayz.co.kr/media/upload';

function fadeOutSound () {
  return new Promise(resolve => {
    const video = videoObject.video;
    const fadeoutInterval = setInterval(fadeOut, 500);
    function fadeOut () {
      if (Hls.isSupported()) {
        if (video.volume > 0.1) {
          video.volume -= 0.1; // Decrease the volume by 10% every 500ms
        } else {
          video.volume = 0;
          video.muted = true;
          clearInterval(fadeoutInterval); // Stop the interval when the volume reaches zero
          resolve();
        }
      }
    }
  });
}

function fadeInSound () {
  return new Promise(resolve => {
    const video = videoObject.video;
    const fadeoutInterval = setInterval(fadein, 500);
    const volume = parseFloat(playermodule.getters.getVolume(playermodule.state));

    function fadein () {
      // Not IOS
      if (Hls.isSupported()) {
        if (video.volume < volume) {
          const val = video.volume + 0.1;
          if (val > 1) {
            video.volume = 1;
          } else {
            console.log('volume up');
            video.volume = val; // Decrease the volume by 10% every 500ms
          }
        } else {
          clearInterval(fadeoutInterval); // Stop the interval when the volume reaches zero
          resolve();
        }
      } else {
        resolve();
      }
    }
  });
}

function getSpaceName () {
  const newArray = [
    playermodule.getters.getMyChannelInfo(playermodule.state),
    ...playermodule.getters.getSimilarChannelList(playermodule.state),
    ...playermodule.getters.getPopularityChannelList(playermodule.state),
    ...playermodule.getters.getDesignatedRecommendedChannels(playermodule.state)
  ];

  if (store.state.channelId !== '') {
    const find = newArray.find(item => item && item.channelId === store.state.channelId);
    if (find) {
      return find.spaceNm;
    } else {
      return store.getters.getSpaceNm;
    }
  }
  return store.getters.getSpaceNm;
}

function setChannelMusic () {
  const channelId = store.state.channelId;
  const aToken = getCookie('aToken');
  const headers = { 'X-AUTH-TOKEN': aToken };
  getChannelMusicInfo(channelId, headers)
    .then(res => {
      const result = res.data.result;
      result.spaceName = getSpaceName();
      store.commit('setChannelMusicInfo', result);
      setCheckLike();
    })
    .catch(err => {
      store.commit('setChannelMusicInfo', null);
      console.log('setChannelMusicInfo err : ', err);
    });
}

function setCheckLike () {
  const spaceId = getCookie('spaceId');
  const userId = getCookie('userId');
  const aToken = getCookie('aToken');
  const headers = { 'X-AUTH-TOKEN': aToken };
  const musicPath = store.state.channelMusicInfo.musicId;
  if (spaceId) {
    checkLike(spaceId, userId, musicPath, headers).then(res => {
      if (res.data.resultCd === '0000') {
        const result = res.data.result;
        const likeInfo = {
          like: result.like,
          unlike: result.unlike
        };
        store.commit('setLikeInfo', likeInfo);
      }
    });
  }
}

function setMyChannelInfo () {
  const spaceId = getCookie('spaceId');
  if (spaceId) {
    selectSimilarChannels(spaceId).then(res => {
      const { resultCd, result } = res.data;
      if (resultCd === '0000') {
        const findMyChannel = result.find(item => item.spaceId === spaceId);
        if (findMyChannel) {
          playermodule.mutations.setStartChannelInfo(playermodule.state, {
            ...findMyChannel,
            activeTab: 'my'
          });
          playermodule.mutations.setMyChannelInfo(playermodule.state, findMyChannel);
        } else {
          playermodule.mutations.setMyChannelInfo(playermodule.state, null);
        }
      }
    });
  }
}

function setCurationInfo () {
  const spaceId = getCookie('spaceId');
  const aToken = getCookie('aToken');
  getSpaceCurationInfo(spaceId, {
    'X-AUTH-TOKEN': aToken
  }).then(res => {
    if (res.data.resultCd === '0000') {
      playermodule.mutations.setCurationInfo(playermodule.state, res.data.result);
    }
  });
}

async function retryPlay (n = 0, successFn = () => {}, failFn) {
  if (n < 3) {
    setTimeout(() => {
      videoObject
        .onPlay()
        .then(() => {
          successFn();
        })
        .catch(err => {
          // store.commit('setPlay', false);
          console.log('err : ', err.message);
          console.log('재생 오류 ');
          retryPlay(n + 1, successFn, failFn);
        });
    }, 1000);
  } else {
    console.log('fail : ', n);
    store.commit('setPlay', false);
    failFn();
  }
}

async function retryChannelUrl (n = 0, successFn = () => {}, failFn = () => {}, playerinfofn = null) {
  return await new Promise((resolve, reject) => {
    if (n < 200) {
      setTimeout(async () => {
        return await fetch(store.state.channelUrl)
          .then(async () => {
            videoObject.registerVideoOnceEvent('play', () => {
              console.log('success play');
              store.commit('setPlay', true);
              playermodule.mutations.setIsLoading(playermodule.state, false);
              playermodule.mutations.setLoadingStatus(playermodule.state, false);
            });

            try {
              const spaceId = getCookie('spaceId') ? getCookie('spaceId') : null;
              const { data } = await getChannelInfo(store.state.channelId, spaceId);
              const { resultCd, result } = data;
              if (resultCd === '0000') {
                playermodule.mutations.setChannelInfo(playermodule.state, result);
                const { channelType } = result;
                // B,T
                if (channelType === 'B') {
                  const {
                    targetList,
                    targetUnitList,
                    domesticRate,
                    genreList,
                    genreTuneNList,
                    genreTuneYList,
                    periodList,
                    carPurposeList,
                    spaceType
                  } = result;

                  let genreTotal = [];

                  if (genreList) {
                    genreTotal = [...genreList];
                  }

                  if (genreTuneNList) {
                    genreTotal = [...genreTotal, ...genreTuneNList];
                  }

                  if (genreTuneYList) {
                    genreTotal = [...genreTotal, ...genreTuneYList];
                  }

                  if (spaceType === 'Store') {
                    playermodule.mutations.setTagList(playermodule.state, [
                      { isOpen: false, value: targetList },
                      { isOpen: false, value: targetUnitList },
                      { isOpen: false, value: `국내 ${domesticRate}%` },
                      { isOpen: false, value: genreTotal },
                      { isOpen: false, value: periodList.map(item => `${item}s`) }
                    ]);
                  } else {
                    playermodule.mutations.setTagList(playermodule.state, [
                      { isOpen: false, value: carPurposeList },
                      { isOpen: false, value: `국내 ${domesticRate}%` },
                      { isOpen: false, value: genreTotal },
                      { isOpen: false, value: periodList.map(item => `${item}s`) }
                    ]);
                  }

                  // setBg(channelType);
                } else {
                  const { recomTagList } = result;
                  const recom = recomTagList.map(item => {
                    return {
                      isOpen: false,
                      value: item
                    };
                  });
                  playermodule.mutations.setTagList(playermodule.state, recom);
                }
                if (playerinfofn) {
                  playerinfofn();
                }
              } else {
                failFn();
                store.commit('setPlay', false);
              }
              setChannelMusic();
            } catch (error) {
              store.commit('setPlay', false);
              console.error('getChannelInfo error ', error);
            }
            videoObject.playerMusic(store.state.channelUrl);
            videoObject.startLoad(-1);
            retryPlay(0, successFn, failFn);
          })
          .catch(_ => {
            retryChannelUrl(n + 1, successFn, failFn);
          });
      }, 1500);
    } else {
      reject(new Error('fail'));
      failFn();
    }
  });
}

const playermodule = {
  namespaced: true,
  state: {
    myChannelInfo: null,
    tagList: [],
    alertStatus: false,
    isMute: sessionStorage.getItem('isMute') === 'true',
    curationInfo: {},
    originalUrl: null,
    channelInfo: null,
    originalChannelInterval: null,
    spaceInfo: {},
    isLoading: false,
    loadingStatus: false,
    channelStatus: null,
    recomList: [],
    volume: sessionStorage.getItem('volume') ? sessionStorage.getItem('volume') : 0.6,
    playerInfo: null,
    tutorial: {
      step1: false,
      step2: false
    },
    tutorialStauts: false,
    curation: {
      mood: ['모던', '럭셔리', '빈티지', '아기자기', '트렌디'],
      target: ['유아/아동', '청소년', '청년', '중장년', '노년'],
      targetUnits: ['혼자', '친구', '연인', '가족', '동료'],
      carMood: ['차분한 카페', '럭셔리한 호텔', '신나는 클럽', '활기찬 헬스장', '힐링 스파샵', '빈티지', '아기자기'],
      carPurpose: ['출근', '퇴근', '근거리 드라이브', '장거리 운행', '업무중', '일상생활', '여행'],
      genre: [
        '발라드',
        '랩/힙합',
        '댄스',
        'R&B/Soul',
        '재즈',
        '일렉트로니카',
        '인디음악',
        '록/메탈',
        '포크/블루스/컨트리',
        '트로트',
        '클래식',
        '뉴에이지',
        '캐롤'
      ],
      period: ['~1980', '1990', '2000', '2010', '2020'],
      emotion: ['차분한', '편안한', '활기찬', '신나는', '슬픈', '쓸쓸한', '독특한', '파워풀한', '흥분되는']
    },
    weatherInfo: {},
    settingSpaceYn: 'N',
    popularityChannelList: [],
    designatedRecommendedChannels: [],
    similarChannelsList: [],
    bookmarkChannels: [],
    activeTab: '',
    isOpenAnim: false,
    isOpenCurationSetting: false,
    isAlertModal: false,
    alertText: '',
    brandMusicList: [],
    recommInterval: -1,
    recommUpdate: null,
    channelTrackList: [],

    startChannelInfo: {},
    isShowRoomChecking: false,
    creatorCurationId: '',
    timeCurationList: [],
    isAddedJobInitFreePlayer: false
  },
  getters: {
    getIsAlertModal (state) {
      return state.isAlertModal;
    },
    getMyChannelInfo (state) {
      return state.myChannelInfo;
    },
    getTagList (state) {
      return state.tagList;
    },
    getAlertStatus (state) {
      return state.alertStatus;
    },
    getIsMute (state) {
      return state.isMute;
    },
    getVolume (state) {
      return state.volume;
    },
    getCurationInfo (state) {
      return state.curationInfo;
    },
    getTutorial (state) {
      return state.tutorial;
    },
    getOriginalUrl (state) {
      return state.originalUrl;
    },
    getChannelInfo (state) {
      return state.channelInfo;
    },
    getOriginalChannelInterVal (state) {
      return state.originalChannelInterval;
    },
    getSpaceInfo (state) {
      return state.spaceInfo;
    },
    getIsLoading (state) {
      return state.isLoading;
    },
    getLoadingStatus (state) {
      return state.loadingStatus;
    },
    getChannelStatus (state) {
      return state.channelStatus;
    },
    getRecomList (state) {
      return state.recomList;
    },
    getTutorialStauts (state) {
      return state.tutorialStauts;
    },
    getCurationValue (state) {
      return state.curation;
    },
    getCurationStoreMode: state => val => {
      const findIdx = state.curation.mood.findIndex(item => item === val);

      return findIdx > -1 ? findIdx + 1 : 0;
    },
    getCurationStoreTarget: state => val => {
      const findIdx = state.curation.target.findIndex(item => item === val);
      return findIdx > -1 ? findIdx + 1 : 0;
    },
    getCurationStoreTargetUnits: state => val => {
      const findIdx = state.curation.targetUnits.findIndex(item => item === val);
      return findIdx > -1 ? findIdx + 1 : 0;
    },
    getCurationStoreGenre: state => val => {
      const findIdx = state.curation.genre.findIndex(item => item === val);
      return findIdx > -1 ? findIdx + 1 : 0;
    },
    getCurationStorePeroid: state => val => {
      const findIdx = state.curation.period.findIndex(item => item === val);
      return findIdx > -1 ? findIdx + 1 : 0;
    },
    getCurationStoreEmotion: state => val => {
      const findIdx = state.curation.emotion.findIndex(item => item === val);
      return findIdx > -1 ? findIdx + 1 : 0;
    },
    getCarMode: state => val => {
      const findIdx = state.curation.carMood.findIndex(item => item === val);

      return findIdx > -1 ? findIdx + 1 : 0;
    },
    getCarPurpose: state => val => {
      const findIdx = state.curation.carPurpose.findIndex(item => item === val);
      return findIdx > -1 ? findIdx + 5 : 0;
    },
    getGenreFilterArray: () => val => {
      if (val === 1) {
        return [1, 4, 5, 11];
      } else if (val === 2) {
        return [1, 4, 5, 9];
      } else if (val === 3) {
        return [1, 3, 4, 9];
      } else if (val === 4) {
        return [1, 3, 4, 9];
      } else if (val === 5) {
        return [1, 2, 3, 4];
      } else {
        return [];
      }
    },
    getGenreFilter: () => val => {
      if (val === 1) {
        return [1, 4, 5, 11].join(',');
      } else if (val === 2) {
        return [1, 4, 5, 9].join(',');
      } else if (val === 3) {
        return [1, 3, 4, 9].join(',');
      } else if (val === 4) {
        return [1, 3, 4, 9].join(',');
      } else if (val === 5) {
        return [1, 2, 3, 4].join(',');
      } else {
        return '';
      }
    },
    getEmotionFilterArray: () => val => {
      if (val === 1) {
        return [1, 2, 3, 4];
      } else if (val === 2) {
        return [1, 2, 3, 4];
      } else if (val === 3) {
        return [1, 2, 3, 4];
      } else if (val === 4) {
        return [1, 2, 3, 4];
      } else if (val === 5) {
        return [3, 4, 8, 9];
      } else {
        return [];
      }
    },
    getEmotionFilter: () => val => {
      if (val === 1) {
        return [1, 2, 3, 4].join(',');
      } else if (val === 2) {
        return [1, 2, 3, 4].join(',');
      } else if (val === 3) {
        return [1, 2, 3, 4].join(',');
      } else if (val === 4) {
        return [1, 2, 3, 4].join(',');
      } else if (val === 5) {
        return [3, 4, 8, 9].join(',');
      } else {
        return '';
      }
    },
    getTargetUnitFilter: () => val => {
      if (val === 1) {
        return [4];
      } else if (val === 2) {
        return [1, 2, 3, 4];
      } else if (val === 3) {
        return [1, 2, 3, 4, 5];
      } else if (val === 4) {
        return [1, 2, 3, 4, 5];
      } else if (val === 5) {
        return [1, 2, 3, 4, 5];
      } else {
        return [];
      }
    },
    getCarGenreFilter: () => val => {
      if (val === 5) {
        // 출근
        return [3, 4, 9];
      } else if (val === 6) {
        // 퇴근
        return [1, 3, 4];
      } else if (val === 7) {
        // 근거리 드라이브
        return [1, 3, 4];
      } else if (val === 8) {
        // 장거리 운행
        return [1, 3, 4];
      } else if (val === 9) {
        // 업무중
        return [1, 4, 9];
      } else if (val === 10) {
        // 일상생활
        return [1, 3, 9];
      } else if (val === 11) {
        // 여행
        return [3, 8, 9];
      } else {
        return [];
      }
    },
    getCarEmotionFilter: () => val => {
      if (val === 5) {
        return [3, 4, 8, 9];
      } else if (val === 6) {
        return [3, 4, 8, 9];
      } else if (val === 7) {
        return [1, 2, 4, 7];
      } else if (val === 8) {
        return [1, 2, 4, 7];
      } else if (val === 9) {
        return [1, 2, 3, 4];
      } else if (val === 10) {
        return [1, 2, 5, 6];
      } else if (val === 11) {
        return [3, 4, 7, 9];
      } else {
        return [];
      }
    },
    getCarMoodFilter: () => val => {
      // 출근
      if (val === 5) {
        return 2;
      } else if (val === 6) {
        // 퇴근
        return 6;
      } else if (val === 7) {
        // 근거리 드라이브
        return 7;
      } else if (val === 8) {
        // 장거리 운행
        return 3;
      } else if (val === 9) {
        // 업무중
        return 1;
      } else if (val === 10) {
        // 일상생활
        return 7;
      } else if (val === 11) {
        // 여행
        return 3;
      }

      return -1;
    },
    getWeatherInfo (state) {
      return state.weatherInfo;
    },
    getSettingSpaceYn (state) {
      return state.settingSpaceYn;
    },
    getPlayerInfo (state) {
      return state.playerInfo;
    },
    getPopularityChannelList (state) {
      return state.popularityChannelList;
    },
    getDesignatedRecommendedChannels (state) {
      return state.designatedRecommendedChannels;
    },
    getSimilarChannelList (state) {
      return state.similarChannelsList;
    },
    getBookmarkChannels (state) {
      return state.bookmarkChannels || [];
    },
    getActiveTab (state) {
      return state.activeTab;
    },
    getIsOpenAnim (state) {
      return state.isOpenAnim;
    },
    getIsOpenCurationSetting (state) {
      return state.isOpenCurationSetting;
    },
    getAlertText (state) {
      return state.alertText;
    },
    getBrandMusicList (state) {
      return state.brandMusicList;
    },
    getMobileChannelList (state) {
      return [
        {
          ...state.myChannelInfo,
          activeTab: 'my'
        },
        ...state.similarChannelsList.map(item => {
          return {
            ...item,
            activeTab: 'similar'
          };
        }),
        ...state.popularityChannelList.map(item => {
          return {
            ...item,
            activeTab: 'popopularpul'
          };
        }),
        ...state.designatedRecommendedChannels.map(item => {
          return {
            ...item,
            activeTab: 'design'
          };
        })
      ];
    },
    getRecommDateHHMM (state) {
      if (state.recommUpdate && typeof state.recommUpdate === 'object') {
        const HH =
          state.recommUpdate.getHours() > 9 ? state.recommUpdate.getHours() : `0${state.recommUpdate.getHours()}`;
        return `${HH}:00`;
      }
      return null;
    },
    getChannelTrack (state) {
      return state.channelTrackList;
    },
    getStartChannelInfo (state) {
      return state.startChannelInfo;
    },
    getIsShowRoomChecking (state) {
      return state.isShowRoomChecking;
    },
    getCreatorCurationId (state) {
      return state.creatorCurationId;
    },
    getTimeCurationList (state) {
      return state.timeCurationList;
    }
  },
  mutations: {
    setRecommInterval (state) {
      state.recommInterval = setInterval(() => {
        state.recommUpdate = new Date();
      }, 1000);
    },
    setRecommDate (state) {
      state.recommUpdate = new Date();
    },
    setMyChannelInfo (state, payload) {
      const cloneMyChannel = clone(payload);
      let src = '/media/img/car.png';
      if (cloneMyChannel) {
        if (cloneMyChannel && Object.keys(cloneMyChannel).includes('brandImg')) {
          src = `${baseImgUrl}${cloneMyChannel.brandImg}`;
        } else {
          if (cloneMyChannel.spaceType !== 'Car' && cloneMyChannel.channelType === 'B') {
            if (cloneMyChannel.sector === '카페') {
              src = '/media/img/cafe.png';
            } else if (cloneMyChannel.sector === '음식점') {
              src = '/media/img/food.png';
            } else if (cloneMyChannel.sector === '주점') {
              src = '/media/img/drink.png';
            } else if (cloneMyChannel.sector === '병원') {
              src = '/media/img/hospital.png';
            } else if (cloneMyChannel.sector === '편의시설') {
              src = '/media/img/Facilities.png';
            } else if (cloneMyChannel.sector === '숙박시설') {
              src = '/media/img/thumnail_hotel.png';
            } else if (cloneMyChannel.sector === '기타') {
              src = '/media/img/Group 485982.png';
            } else if (cloneMyChannel.sector == null) {
              src = '/media/img/Group 485982.png';
            }
          }
        }
        cloneMyChannel.src = src;
        cloneMyChannel.imgUrl = src;
        state.myChannelInfo = cloneMyChannel;
      }
    },
    setTagList (state, payload) {
      state.tagList = payload;
    },
    setTagIsOpenChange (state, payload) {
      const copyOriginal = cloneDeep(state.tagList);
      // 기존꺼 닫기
      copyOriginal.forEach(element => {
        element.isOpen = false;
      });
      copyOriginal[payload.index].isOpen = payload.isOpen;

      state.tagList = copyOriginal;
    },
    setAlertStatus (state, payload) {
      state.alertStatus = payload;
    },
    setIsMute (state, payload) {
      sessionStorage.setItem('isMute', payload);
      state.isMute = payload;
    },
    setWeatherInfo (state, payload) {
      state.weatherInfo = payload;
    },
    setCurationInfo (state, payload) {
      state.curationInfo = payload;
    },
    setOriginalUrl (state, payload) {
      state.originalUrl = payload;
    },
    setChannelInfo (state, payload) {
      state.channelInfo = payload;
      if (payload) {
        if (payload.channelType === 'T') {
          mediaSessionObject.setSpaceImgMetaData(`${process.env.VUE_APP_BASE_URL}${payload.imgUrl}`);
        } else {
          const spaceImg = payload.brandImg ? `${baseImgUrl}${payload.brandImg}` : payload.spaceImg;
          mediaSessionObject.setSpaceImgMetaData(spaceImg);
        }
      }
    },
    setOriginalChannelInterval (state, payload) {
      state.originalChannelInterval = payload;
    },
    setSpaceInfo (state, payload) {
      state.spaceInfo = payload;
    },
    setIsLoading (state, payload) {
      state.isLoading = payload;
    },
    setLoadingStatus (state, payload) {
      state.loadingStatus = payload;
    },
    setChannelStatus (state, payload) {
      state.channelStatus = payload;
    },
    setRecomList (state, payload) {
      state.recomList = payload;
    },
    setTutorial (state, payload) {
      state.tutorial = payload;
    },
    setTutorialStauts (state, payload) {
      state.tutorialStauts = payload;
    },
    setVolume (state, payload) {
      sessionStorage.setItem('volume', payload);
      state.volume = payload;
    },
    setSettingSpaceYn (state, payload) {
      state.settingSpaceYn = payload;
    },
    setPlayerInfo (state, payload) {
      state.playerInfo = payload;
    },
    setPopularityChannelList (state, payload) {
      state.popularityChannelList = payload;
    },
    setDesignatedRecommendedChannels (state, payload) {
      state.designatedRecommendedChannels = payload;
    },
    setSimilarChannelList (state, payload) {
      const spaceId = getCookie('spaceId');
      const findIndex = payload.findIndex(item => item.spaceId === spaceId);
      if (findIndex > -1) {
        payload.splice(findIndex, 1);
      }
      const cloneSimilarChannelList = cloneDeep(payload);
      cloneSimilarChannelList.forEach(item => {
        let src = '/media/img/car.png';

        if (item.brandImg != null) {
          src = `${baseImgUrl}${item.brandImg}`;
        } else {
          if (item.spaceType !== 'Car' && item.channelType === 'B') {
            if (item.sector === '카페') {
              src = '/media/img/cafe.png';
            } else if (item.sector === '음식점') {
              src = '/media/img/food.png';
            } else if (item.sector === '주점') {
              src = '/media/img/drink.png';
            } else if (item.sector === '병원') {
              src = '/media/img/hospital.png';
            } else if (item.sector === '편의시설') {
              src = '/media/img/Facilities.png';
            } else if (item.sector === '숙박시설') {
              src = '/media/img/thumnail_hotel.png';
            } else if (item.sector === '기타') {
              src = '/media/img/Group 485982.png';
            } else if (item.sector == null) {
              src = '/media/img/Group 485982.png';
            }
          }
        }
        item.src = src;
      });

      state.similarChannelsList = cloneSimilarChannelList;
    },
    setBookmarkChannels (state, payload) {
      state.bookmarkChannels = payload;
    },
    setActiveTab (state, payload) {
      state.activeTab = payload;
    },
    setIsOpenAnim (state, payload) {
      state.isOpenAnim = payload;
    },
    setIsOpenCurationSetting (state, payload) {
      state.isOpenCurationSetting = payload;
    },

    setIsAlertModal (state, payload) {
      state.isAlertModal = payload;
    },
    setAlertText (state, payload) {
      state.alertText = payload;
    },
    setBrandMusicList (state, payload) {
      state.brandMusicList = payload;
    },
    setChannelTrack (state, payload) {
      state.channelTrackList = payload;
    },
    setStartChannelInfo (state, payload) {
      state.startChannelInfo = payload;
    },

    setIsShowRoomEnterCheck (state, payload) {
      state.isShowRoomChecking = payload;
    },
    setCreatorCurationId (state, payload) {
      state.creatorCurationId = payload;
    },
    setTimeCurationList (state, payload) {
      state.timeCurationList = payload;
    }
  },
  actions: {
    async settingMyChannelInfo () {
      setMyChannelInfo();
    },
    async refreshChannel ({ commit }, payload = { retry: false }) {
      commit('setLoadingStatus', true);
      videoObject.onPause();
      store.commit('setPlay', false);
      const spaceId = getCookie('spaceId');
      const userId = getCookie('userId');
      const aToken = getCookie('aToken');
      const { data: preferMusicData } = await postRandomPreferMusic(spaceId);
      const { resultCd: preferMusicResult } = preferMusicData;
      if (preferMusicResult === '0000') {
        const response = await createChannel({ userId, spaceId, aToken });
        const result = response.data.result;
        store.commit('setChannelId', result.channelId);
        store.commit('setChannelUrl', result.url);
        store.commit('player/setOriginalUrl', result.url);
        commit('setActiveTab', 'my');
        setMyChannelInfo();
        retryChannelUrl(
          0,
          () => {
            store.commit('setPlay', true);
            getSpaceCurationInfo(spaceId, {
              'X-AUTH-TOKEN': aToken
            })
              .then(res => {
                if (res.data.resultCd === '0000') {
                  commit('setCurationInfo', res.data.result);
                }
              })
              .finally(() => {
                commit('setLoadingStatus', false);
              });
          },
          () => {
            console.log('fail');
            store.commit('setPlay', false);
            commit('setLoadingStatus', false);
          },
          payload.playerinfofn ? payload.playerinfofn : null
        );
      } else {
        commit('setLoadingStatus', false);
      }
    },
    async createChannel ({ commit, state, dispatch }, payload = { retry: false, ios: false }) {
      if (store.state.channelId === '') {
        const spaceId = getCookie('spaceId');
        const userId = getCookie('userId');
        const aToken = getCookie('aToken');
        if (!payload.retry) {
          commit('setIsLoading', true);
        }

        try {
          const response = await createChannel({ userId, spaceId, aToken });
          const { resultCd } = response.data;
          const result = response.data.result;
          const { channelId, url, creatorCurationId } = result;
          commit('setCreatorCurationId', creatorCurationId);
          setCurationInfo();
          dispatch('addCronJob');
          if (resultCd === '0000') {
            store.commit('setChannelId', channelId);
            store.commit('setChannelUrl', url);
            store.commit('player/setOriginalUrl', url);
            setMyChannelInfo();
            if (!Object.keys(payload).includes('ios')) {
              retryChannelUrl(
                0,
                () => {
                  if (state.isLoading) {
                    commit('setIsLoading', false);
                  }

                  if (state.loadingStatus) {
                    commit('setLoadingStatus', false);
                  }
                },
                () => {
                  console.log('fail');
                  commit('setIsLoading', false);
                  commit('setLoadingStatus', false);
                },
                payload.playerinfofn ? payload.playerinfofn : null
              );
            }

            commit('setActiveTab', 'my');
            commit('setRecommDate');
            commit('setRecommInterval');
            return true;
          } else {
            commit('setIsLoading', false);
            commit('setLoadingStatus', false);
            return false;
          }
        } catch (error) {
          console.error('error : ', error);
          commit('setIsLoading', false);
          return false;
        }
      }
    },
    async changeTimeCuration ({ commit }, payload) {
      const isBrandMuisc = isNestedBrandMusic(payload.curationId);
      const isAutoPlay = isNestedAutoPlayMusic(payload.curationId);

      const getSpaceId = getCookie('spaceId');
      const getUserId = getCookie('userId');
      const getAToken = getCookie('aToken');

      if (!isAutoPlay) {
        try {
          const { data: createChannelData } = await createChannel({
            userId: getUserId,
            spaceId: getSpaceId,
            aToken: getAToken
          });
          const { resultCd, result: createResult } = createChannelData;
          await fadeOutSound();
          if (resultCd === '0000') {
            // 채널 생성(큐레이션Id,채널Id,채널Url)

            const { channelId, url, creatorCurationId } = createResult;
            commit('setCreatorCurationId', creatorCurationId);
            setCurationInfo();
            store.commit('setChannelId', channelId);
            store.commit('setChannelUrl', url);
            store.commit('player/setOriginalUrl', url);
            setMyChannelInfo();

            commit('setActiveTab', 'my');
          }
        } catch (error) {
          //
        } finally {
          retryChannelUrl(
            0,
            () => {
              // 브랜드 뮤직이 켜져있지 않는 경우 &&  자동재생/중지가 아닌경우 => 플레이
              if (!isBrandMuisc) {
                if (sessionStorage.getItem('isMute') === 'false' || !sessionStorage.getItem('isMute')) {
                  videoObject.setMuted(false);
                  fadeInSound();
                }
              }
            },
            () => {
              // 브랜드 뮤직이 켜져있지 않는 경우 &&  자동재생/중지가 아닌경우  플레이
              if (!isBrandMuisc) {
                if (sessionStorage.getItem('isMute') === 'false' || !sessionStorage.getItem('isMute')) {
                  videoObject.setMuted(false);
                  fadeInSound();
                }
              }
            }
          );
        }
      }
    },
    async addCronJob ({ dispatch }) {
      try {
        const array = Object.keys(VueCrontab.jobs).filter(item => item.includes('timecuration'));
        // 기존꺼 제거
        if (array.length > 0) {
          const len = array.length;
          for (let i = 0; i < len; i++) {
            const key = array[i];
            VueCrontab.deleteJob(key);
          }
        }

        const spaceId = getCookie('spaceId');
        selectCurationInfoAll(spaceId).then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            const list = result || [];
            list.forEach(element => {
              const { startTime, endTime, curationId, dayOfWeek } = element;
              if (startTime && endTime) {
                const startHour = startTime.split(':')[0];
                const startMin = startTime.split(':')[1];
                const endHour = endTime.split(':')[0];
                const endMin = endTime.split(':')[1];
                const daylist = dayOfWeek
                  .split(',')
                  .map(item => {
                    if (parseInt(item) === 7) {
                      return 0;
                    }
                    return item;
                  })
                  .join(',');
                // 시작 종료
                VueCrontab.addJob([
                  {
                    name: `timecuration_start_${curationId}`,
                    interval: {
                      seconds: '0',
                      minutes: startMin,
                      hours: startHour,
                      week: daylist
                    },
                    job: async () => {
                      console.log('start trigger');
                      // 브랜드음원과 겹치는지 확인

                      // 시작시간과 겹치는 종료시간이 존재하지 않을 경우 FadeOut => CreateChannel => FadeIn;
                      const jobInfo = VueCrontab.jobs[`timecuration_start_${curationId}`];
                      const { hours, minutes } = jobInfo.intervals[0];
                      const filter = Object.keys(VueCrontab.jobs).filter(item => item.includes('timecuration_end'));
                      const findItem = filter.find(item => {
                        const findItem = VueCrontab.jobs[item];
                        const { hours: diffHour, minutes: diffMin } = findItem.intervals[0];

                        if (hours === diffHour && minutes === diffMin) {
                          return true;
                        }

                        return false;
                      });

                      if (!findItem) {
                        dispatch('changeTimeCuration', { curationId: `timecuration_start_${curationId}` });
                      }
                    }
                  },
                  {
                    name: `timecuration_end_${curationId}`,
                    interval: {
                      seconds: '0',
                      minutes: endMin,
                      hours: endHour,
                      week: daylist
                    },
                    job: async () => {
                      console.log('end trigger');
                      // 브랜드음원과 겹치는지 확인
                      dispatch('changeTimeCuration', { curationId: `timecuration_end_${curationId}` });
                    }
                  }
                ]);
              }
            });
          }
        });
      } catch (error) {
        //
        console.error('addCronJob error :  ', error);
      }
    },
    async joinChannel ({ commit, dispatch, state }, payload) {
      if (payload.channelId !== store.state.channelId && !state.loadingStatus) {
        commit('setChannelStatus', 'change');
        commit('setLoadingStatus', true);
        const spaceId = getCookie('spaceId');
        const userId = getCookie('userId');
        return await joinRecomChannel(userId, spaceId, store.state.channelId, payload.channelId)
          .then(res => {
            const { result } = res.data;

            if (result.channelName === '사용할 수 없는 채널입니다.') {
              if (payload.channelType !== 'B') {
                commit('setChannelStatus', '');
                commit('setLoadingStatus', false);
                commit('setAlertStatus', true);
              } else {
                commit('setChannelStatus', '');
                store.commit('setChannelId', '');
                dispatch('createChannel', {
                  retry: true,
                  playerinfofn: payload.playerinfofn ? payload.playerinfofn : null
                });
                return false;
              }

              return false;
            }
            store.commit('setChannelId', result.channelId);
            store.commit('setChannelUrl', result.url);
            // 좋아요 싫어요 처리

            retryChannelUrl(
              0,
              () => {
                commit('setChannelStatus', '');
                commit('setLoadingStatus', false);
              },
              () => {},
              payload.playerinfofn ? payload.playerinfofn : null
            );

            return true;
          })
          .catch(err => {
            if (err.response) {
              const { status } = err.response;
              if (status === 500) {
                commit('setChannelStatus', '');
                commit('setLoadingStatus', false);
                commit('setAlertStatus', true);
              }
            } else {
              commit('setLoadingStatus', false);
            }
          });
      }
    },
    async manageNonSpaceBookmark ({ commit, state }, payload) {
      //
      const userId = getCookie('userId');
      const findBookmark = state.bookmarkChannels.find(item => item.recomId === payload.recomId);
      let isInsert = true;
      if (findBookmark) {
        commit('setIsOpenAnim', false);
        isInsert = false;

        await deleteUserBookmarkChannel({ recomId: payload.recomId, state: 'N', userId });
      } else {
        commit('setIsOpenAnim', true);
        isInsert = true;
        await insertUserBookmarkChannel({ recomId: payload.recomId, state: 'Y', userId });
      }

      selectUserBookmarkChannelList(userId).then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          commit('setBookmarkChannels', result);

          // 현재 채널이 즐겨찾기에 없으면, '인기 급상승', '이런 채널은 어때요?' , 검색 후 활성화 처리
          const designated = state.designatedRecommendedChannels.map(item => {
            return {
              ...item,
              activeTab: 'design'
            };
          });
          if (!isInsert) {
            const popular = state.popularityChannelList.map(item => {
              return {
                ...item,
                activeTab: 'popular'
              };
            });

            const newArray = [...popular, ...designated];
            const findChannel = newArray.find(item => item.channelId === store.state.channelId);
            if (findChannel) {
              commit('setActiveTab', findChannel.activeTab);
              commit('setStartChannelInfo', {
                ...findChannel,
                activeTab: findChannel.activeTab
              });
            }
          } else {
            //
            const findDesign = designated.find(item => item.channelId === store.state.channelId);
            if (findDesign) {
              commit('setActiveTab', 'bookmark');
              commit('setStartChannelInfo', {
                ...findDesign,
                activeTab: 'bookmark'
              });
            }
          }
        }
      });
    },
    async manageBookmark ({ commit, state }, payload) {
      const spaceId = getCookie('spaceId');
      const findBookmark = state.bookmarkChannels.find(item => item.recomId === payload.channelId);
      let isInsert = true;
      if (findBookmark) {
        commit('setIsOpenAnim', false);
        isInsert = false;
        await deleteBookmarkChannel(spaceId, payload.channelId);
        // 채널 아이디가 bookmarkchannelsList에 있으면 deleteBookmarkChannel
      } else {
        commit('setIsOpenAnim', true);
        isInsert = true;
        await insertBookmarkChannel(spaceId, payload.channelId);
        // 채널 아이디가 bookmarkchannelsList에 없으면 insertBookmarkChannel
      }

      // 즐겨찾기 조회
      selectBookmarkChannels(spaceId).then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          commit('setBookmarkChannels', result);

          // 현재 채널이 즐겨찾기에 없으면, '인기 급상승', '이런 채널은 어때요?' , 검색 후 활성화 처리
          const designated = state.designatedRecommendedChannels.map(item => {
            return {
              ...item,
              activeTab: 'design'
            };
          });
          if (!isInsert) {
            const popular = state.popularityChannelList.map(item => {
              return {
                ...item,
                activeTab: 'popular'
              };
            });

            const newArray = [...popular, ...designated];
            const findChannel = newArray.find(item => item.channelId === store.state.channelId);
            if (findChannel) {
              commit('setActiveTab', findChannel.activeTab);
              commit('setStartChannelInfo', {
                ...findChannel,
                activeTab: findChannel.activeTab
              });
            }
          } else {
            //
            const findDesign = designated.find(item => item.channelId === store.state.channelId);
            if (findDesign) {
              commit('setActiveTab', 'bookmark');
              commit('setStartChannelInfo', {
                ...findDesign,
                activeTab: 'bookmark'
              });
            }
          }
        }
      });
    },
    async showRoomUpdate ({ commit }) {
      //
      try {
        const spaceId = getCookie('spaceId');
        const userId = getCookie('userId');
        const aToken = getCookie('aToken');
        const response = await createChannel({ userId, spaceId, aToken });
        const result = response.data.result;
        store.commit('setChannelId', result.channelId);
        store.commit('setChannelUrl', result.url);
        commit('setOriginalUrl', result.url);
        commit('setActiveTab', 'my');
        setMyChannelInfo();
        retryChannelUrl(
          0,
          () => {
            store.commit('setPlay', true);
            commit('setLoadingStatus', false);
            if (store.getters.gettersBrandActiveIndex <= -1) videoObject.setVolume(0.75);
          },
          () => {
            console.log('fail');
            store.commit('setPlay', false);
            commit('setLoadingStatus', false);
          },
          null
        );
      } catch (error) {
        console.error('showRoomUpdate error : ', error);
      } finally {
        commit('setIsShowRoomEnterCheck', false);
        commit('setLoadingStatus', false);
      }
    },
    async setAutoPlay ({ commit }) {
      const spaceId = getCookie('spaceId');
      const userId = getCookie('userId');
      const aToken = getCookie('aToken');
      try {
        const response = await createChannel({ userId, spaceId, aToken });
        const { resultCd } = response.data;
        setCurationInfo();
        if (resultCd === '0000') {
          const result = response.data.result;
          const { channelId, url } = result;
          store.commit('setChannelId', channelId);
          store.commit('setChannelUrl', url);
          store.commit('player/setOriginalUrl', url);
          setMyChannelInfo();
          commit('setActiveTab', 'my');
          commit('setRecommDate');
          commit('setRecommInterval');
          return true;
        } else {
          commit('setIsLoading', false);
          commit('setLoadingStatus', false);
          return false;
        }
      } catch (error) {
        console.error('error : ', error);
        commit('setIsLoading', false);
        return false;
      }
    },
    async setNonSpaceJoinChannel ({ commit, state }, payload) {
      try {
        if (payload.channelId !== store.state.channelId && !state.loadingStatus) {
          commit('setChannelStatus', 'change');
          commit('setLoadingStatus', true);
          const userId = getCookie('userId');
          const channelId = store.state.channelId;
          const dstChannelId = payload.channelId;
          const { data: joinChannelData } = await joinRecomChannelUser(userId, dstChannelId, channelId);
          const { resultCd: joinResultCd, result: joinResult } = joinChannelData;
          if (joinResultCd === '0000') {
            if (joinResult.channelName === '사용할 수 없는 채널입니다.') {
              commit('setChannelStatus', '');
              commit('setLoadingStatus', false);
              commit('setAlertStatus', true);
              return false;
            }
            store.commit('setChannelId', joinResult.channelId);
            store.commit('setChannelUrl', joinResult.url);

            retryChannelUrl(
              0,
              () => {
                console.log('success');
                commit('setChannelStatus', '');
                commit('setLoadingStatus', false);
              },
              () => {},
              payload.playerinfofn ? payload.playerinfofn : null
            );
            return true;
          }
        }
      } catch (error) {
        commit('setLoadingStatus', false);
        console.error('error : ', error);
        return false;
      }
    },
    async setNonSpacePlay ({ commit, state }) {
      if (store.state.channelId === '') {
        commit('setIsLoading', true);

        commit('setRecommInterval');
        const userId = getCookie('userId');
        try {
          const { data: userFixData } = await userFixedChannel(userId);
          const { resultCd: userFixResultCd, result: userFixResult } = userFixData;
          if (userFixResultCd === '0000') {
            const { otherChannels, recomChannels } = userFixResult;
            commit('setDesignatedRecommendedChannels', otherChannels);
            commit('setPopularityChannelList', recomChannels);
            const { channelId, imgUrl } = recomChannels[0];
            const { data: joinChannelData } = await joinRecomChannelUser(userId, channelId, null);

            const { resultCd: joinResultCd, result: joinResult } = joinChannelData;
            if (joinResultCd === '0000') {
              const { channelId, url } = joinResult;
              store.commit('setChannelId', channelId);
              store.commit('setChannelUrl', url);
              commit('setActiveTab', 'popular');
              setMyChannelInfo();
              retryChannelUrl(
                0,
                () => {
                  store.commit('setPlay', true);
                  commit('setIsLoading', false);
                  setBg(imgUrl);
                },
                () => {
                  console.log('fail');
                  store.commit('setPlay', false);
                  commit('setIsLoading', false);
                  setBg(imgUrl);
                },
                null
              );
            } else {
              commit('setIsLoading', false);
            }
          } else {
            commit('setDesignatedRecommendedChannels', []);
            commit('setPopularityChannelList', []);
          }
        } catch (error) {
          console.error('setNonSpacePlay error : ', error);
          commit('setIsLoading', false);
        }
      } else {
        // 고정채널
        const concatArray = [...state.popularityChannelList, ...state.designatedRecommendedChannels];
        const findChannelId = concatArray.find(item => item.channelId === store.state.channelId);
        if (findChannelId) {
          setBg(findChannelId.imgUrl);
        }
      }
    },
    async setEndFreeSpace ({ commit }) {
      store.commit('setPlay', false);
      const userId = getCookie('userId');
      commit('setTagList', experiencemodule.state.labeltags[0]);
      getUserName(userId)
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            store.commit('setSpaceNm', `${result}님의 공간에 어울리는 음악은?`);
          } else {
            store.commit('setSpaceNm', '');
          }
        })
        .catch(() => {
          store.commit('setSpaceNm', '');
        });

      getNonmemberFixedChannelList()
        .then(res => {
          const { resultCd, result } = res.data;
          if (resultCd === '0000') {
            commit('setDesignatedRecommendedChannels', result);
          }
        })
        .catch(() => {});

      getNonmemberPopularChannelList().then(res => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          commit('setPopularityChannelList', result);
          const fisrt = result[0];
          setBg(fisrt.imgUrl);
          commit('setIsLoading', false);
          getNonmemberChannelMusicInfo(fisrt.recomId).then(res => {
            const { resultCd: channelMusicInfoResultCd, result: musicinfo } = res.data;
            if (channelMusicInfoResultCd === '0000') {
              store.commit('setChannelMusicInfo', musicinfo);
            }
          });
        }
      });
    },
    async settingCurationInfo ({ commit }) {
      const spaceId = getCookie('spaceId');
      const aToken = getCookie('aToken');
      getSpaceCurationInfo(spaceId, {
        'X-AUTH-TOKEN': aToken
      }).then(res => {
        if (res.data.resultCd === '0000') {
          commit('setCurationInfo', res.data.result);
          const { target, targetUnit, domesticRate, genre, period, carPurpose, spaceType, emotion } = res.data.result;
          if (spaceType === 'Store') {
            commit('setTagList', [
              { isOpen: false, value: target },
              { isOpen: false, value: targetUnit },
              { isOpen: false, value: `국내 ${domesticRate}%` },
              { isOpen: false, value: genre },
              { isOpen: false, value: period.map(item => `${item}s`) },
              { isOpen: false, value: emotion }
            ]);
          } else {
            commit('setTagList', [
              { isOpen: false, value: carPurpose },
              { isOpen: false, value: `국내 ${domesticRate}%` },
              { isOpen: false, value: genre },
              { isOpen: false, value: period.map(item => `${item}s`) },
              { isOpen: false, value: emotion }
            ]);
          }
        }
      });
    }
  }
};
export { playermodule };
