const cesposmodule = {
  namespaced: true,
  state: {
    menuCategory: ["All", "Burger", "Side", "Drink"],
    activeCategory: 0,
    isPaymentModal: false,
    isCesPosCuration: false,
    isCesPosWarning: false,
    cesPosWarningText: '',
    menuCategoryFood: [
      {
        idx: 1,
        category: "Burger",
        price: 9.99,
        menuName: "Egg Bacon Burger",
        imgName: "EggBaconBurger.png"
      },
      {
        idx: 2,
        category: "Burger",
        price: 10.99,
        menuName: "Double Patty Bacon Burger",
        imgName: "DoublePattyBaconBurger.png"
      },
      {
        idx: 3,
        category: "Burger",
        price: 10.99,
        menuName: "Double Patty Cheese Burger",
        imgName: "DoublePattyCheeseBurger.png"
      },
      {
        idx: 4,
        category: "Burger",
        price: 8.99,
        menuName: "Egg Benedict Burger",
        imgName: "EggBenedictBurger.png"
      },
      {
        idx: 5,
        category: "Burger",
        price: 7.99,
        menuName: "Classic Burger",
        imgName: "ClassicBurger.png"
      },
      {
        idx: 6,
        category: "Burger",
        price: 10.99,
        menuName: "Double Spicy 1955",
        imgName: "DoubleSpicy1955.png"
      },
      {
        idx: 7,
        category: "Burger",
        price: 9.99,
        menuName: "Sriracha Mayo Delux",
        imgName: "SrirachaMayoDelux.png"
      },
      {
        idx: 8,
        category: "Side",
        price: 4.99,
        menuName: "Wedge Potatoes",
        imgName: "WedgePotatoes.png"
      },
      {
        idx: 9,
        category: "Side",
        price: 3.99,
        menuName: "French Fries",
        imgName: "FrenchFries.png"
      },
      {
        idx: 10,
        category: "Side",
        price: 9.99,
        menuName: "Onion Rings",
        imgName: "OnionRings.png"
      },
      {
        idx: 11,
        category: "Side",
        price: 3.99,
        menuName: "Cheese Sticks(3pc)",
        imgName: "CheeseSticks(3pc).png"
      },
      {
        idx: 12,
        category: "Drink",
        price: 1.99,
        menuName: "Coke",
        imgName: "Coke.png"
      },
      {
        idx: 13,
        category: "Drink",
        price: 1.99,
        menuName: "Sprite",
        imgName: "Sprite.png"
      },
      {
        idx: 14,
        category: "Drink",
        price: 4.99,
        menuName: "Milk Shake",
        imgName: "MilkShake.png"
      },
      {
        idx: 15,
        category: "Drink",
        price: 5.99,
        menuName: "Draft Beer",
        imgName: "DraftBeer.png"
      }
    ],
    totalMenu: [],
    isSuccess: false,
    recommDummyList: [
      {
        channelId: "DummyChannel1",
        channelType: "D",
        sector: "카페",
        spaceId: "DummySpace1",
        spaceNm: "Karls' jr.",
        spaceType: "Store"
      },
      {
        channelId: "DummyChannel2",
        channelType: "D",
        sector: "음식점",
        spaceId: "DummySpace2",
        spaceNm: "Four Guys",
        spaceType: "Store"
      },
      {
        channelId: "DummyChannel3",
        channelType: "D",
        sector: "음식점",
        spaceId: "DummySpace3",
        spaceNm: "Hardy's Burger",
        spaceType: "Store"
      },
      {
        channelId: "DummyChannel4",
        channelType: "D",
        sector: "음식점",
        spaceId: "DummySpace4",
        spaceNm: "Popeyas",
        spaceType: "Store"
      },
      {
        channelId: "DummyChannel5",
        channelType: "D",
        sector: "음식점",
        spaceId: "DummySpace5",
        spaceNm: "John Jimmy's",
        spaceType: "Store"
      },
      {
        channelId: "DummyChannel6",
        channelType: "D",
        sector: "주점",
        spaceId: "DummySpace6",
        spaceNm: "Delly King",
        spaceType: "Store"
      },
      {
        channelId: "DummyChannel7",
        channelType: "D",
        sector: "음식점",
        spaceId: "DummySpace7",
        spaceNm: "Anna's",
        spaceType: "Store"
      },
      {
        channelId: "DummyChannel8",
        channelType: "D",
        sector: "음식점",
        spaceId: "DummySpace7",
        spaceNm: "Pizza Hot",
        spaceType: "Store"
      },
      {
        channelId: "DummyChannel9",
        channelType: "D",
        sector: "음식점",
        spaceId: "Taco Ring",
        spaceNm: "Anna's",
        spaceType: "Store"
      },
      {
        channelId: "DummyChannel10",
        channelType: "D",
        sector: "음식점",
        spaceId: "Kings Burger",
        spaceNm: "Anna's",
        spaceType: "Store"
      }

    ]
  },
  getters: {
    getCategoryList (state) {
      return state.menuCategory;
    },
    getMenuList (state) {
      return state.menuCategoryFood;
    },
    getActiveCategory (state) {
      return state.activeCategory;
    },
    getTotalMenu (state) {
      return state.totalMenu;
    },
    getIsPaymentModal (state) {
      return state.isPaymentModal;
    },
    getIsSuccess (state) {
      return state.isSuccess;
    },
    getIsCesPosCuration (state) {
      return state.isCesPosCuration;
    },
    getIsCesPosWarning (state) {
      return state.isCesPosWarning;
    },
    getCesPosTextWarning (state) {
      return state.cesPosWarningText;
    },
    getDummyList (state) {
      return state.recommDummyList;
    }
  },
  mutations: {
    setActiveCategory (state, payload) {
      state.activeCategory = payload;
    },
    addMenu (state, payload) {
      const find = state.totalMenu.find(item => item.idx === payload.idx);
      let newTotalItem = {};
      if (find) {
        find.count = find.count + 1;
      } else {
        const copy = Object.assign({}, payload);
        copy.count = 1;
        newTotalItem = copy;

        state.totalMenu = [newTotalItem, ...state.totalMenu];
      }
    },
    deleteMenuUnit (state, payload) {
      const findIndex = state.totalMenu.findIndex(item => item.idx === payload.idx);
      const find = state.totalMenu.find(item => item.idx === payload.idx);
      let { count } = find;
      count--;
      find.count = count;
      if (find.count === 0) {
        state.totalMenu.splice(findIndex, 1);
      }
    },
    deleteMenu (state, payload) {
      const findIndex = state.totalMenu.findIndex(item => item.idx === payload.idx);
      state.totalMenu.splice(findIndex, 1);
    },
    setIsPaymentModal (state, payload) {
      state.isPaymentModal = payload;
    },
    setIsSuccess (state, payload) {
      state.isSuccess = payload;
    },
    clearMenu (state) {
      state.totalMenu = [];
    },
    setIsCesPosCuration (state, payload) {
      state.isCesPosCuration = payload;
    },
    setIsCesPosWarning (state, payload) {
      state.isCesPosWarning = payload;
    },
    setCesPosWarningText (state, payload) {
      state.cesPosWarningText = payload;
    }
  },
  actions: {}
};

export { cesposmodule };
