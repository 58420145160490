
import { updateCurationTemp, updateRandomPreferMusic, updateCuration, updateCurationNotice, getCurationInfo } from "@/service/api/kisokApi";

const kisokmodule = {
  namespaced: true,
  state: {
    spaceInfo: {},
    playerInfo: {},
    loadingStatus: false,
    channelStatus: "",
    spaceName: "",
    selectWeatherAndTime: {
      weather: "1",
      times: "2"
    },
    preInfo: {
      weather: '',
      times: ''
    },
    currentInfo: {
      weather: '1',
      times: '낮'
    },
    dummyChannelList: [
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '찐친치킨 공덕점', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '퍼즐치킨 서대문점', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '우왁치킨 1호점', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '치킨말이 서정점', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '통통닭집', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '클랑치킨', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '해적왕치킨 을왕점', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '웃는치킨', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '팔닭팔닭', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '이즈치킨', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '무다리통닭', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '카페', spaceNm: '카페 숨다', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '카페', spaceNm: '골목길 옆 카페', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '카페', spaceNm: '라플랜드 로스터스', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '카페', spaceNm: '카페수잔나', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '카페', spaceNm: '카페 아인슈', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '카페', spaceNm: '커피 파인스', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '카페', spaceNm: '아인슈크림', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '카페', spaceNm: '슈가빈즈 카페', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '카페', spaceNm: '빈즈앤캬라멜즈', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '카페', spaceNm: '쏠라비치 커피', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '카페', spaceNm: '더 블랙 로스터스', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '편의시설', spaceNm: '알로이문고', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '편의시설', spaceNm: '서평서점', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '편의시설', spaceNm: '끼고보고', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '편의시설', spaceNm: '꺼내보네', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '편의시설', spaceNm: '북스앤워크스', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '편의시설', spaceNm: '리딩북스', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '편의시설', spaceNm: '컬쳐북스', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '편의시설', spaceNm: '해오름서점', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '편의시설', spaceNm: '카툰 앤 카투스', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '편의시설', spaceNm: '다락방 서점', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '편의시설', spaceNm: '파인북스', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '병원', spaceNm: '네이처에코 치과', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '병원', spaceNm: '우리맘내과', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '병원', spaceNm: '아이튼튼이 치과', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '병원', spaceNm: '나이스뷰 성형외과', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '병원', spaceNm: '유앤정 정형외과', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '병원', spaceNm: '옥스포드 고양 치과', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '병원', spaceNm: '해피캣츠 가정의학과', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '병원', spaceNm: '아이사랑 소아과', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '병원', spaceNm: '다나아 이비인후과', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '병원', spaceNm: '한맘 산부인과', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '병원', spaceNm: '연세당근 가정의학과', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '주점', spaceNm: '비어부어봐', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '주점', spaceNm: '비어있네', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '주점', spaceNm: '우리집호프', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '주점', spaceNm: '피쉬앤 비어스', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '주점', spaceNm: '냥냥이맥주 서동점', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '주점', spaceNm: '모퉁이 맥주', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '주점', spaceNm: '럼 앤 위스키', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '주점', spaceNm: '피맥타운', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '주점', spaceNm: '잔짠스', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '주점', spaceNm: '알코홀릭', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '주점', spaceNm: '컨트리비어 호프', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '일개미식당', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '저기식당', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '로드 투 이태리', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '알리오', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '동네집밥', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '스타 랍스타', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '알타리 곱창 중앙점', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '올라운드 키친', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '정미기사식당', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '피제리아119', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '음식점', spaceNm: '산더미샤브 반오점', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '숙박시설', spaceNm: '안데르센 호텔', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '숙박시설', spaceNm: '소피텔 ', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '숙박시설', spaceNm: '호텔 루나비치', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '숙박시설', spaceNm: '아펠스트릿 호텔', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '숙박시설', spaceNm: '베라크루즈 호텔', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '숙박시설', spaceNm: '호텔 폴링문', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '숙박시설', spaceNm: '트리비치 레지던스', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '숙박시설', spaceNm: '리틀헤븐 펜션', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '숙박시설', spaceNm: '경포 미드나잇 호텔', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '숙박시설', spaceNm: '디나인 호텔', myChannelYn: false },
      { brandImg: null, spaceType: 'Store', sector: '숙박시설', spaceNm: '더 오션크루즈 호텔', myChannelYn: false }
    ],
    channelInfo: {},
    channelMusicInfo: {},
    curationInfo: {},
    curationTemp: {
      weather: null,
      times: null,
      mood: null,
      domesticRate: null,
      genre: [],
      period: [],
      emotion: []
    },
    kisokAlertModal: false,
    kisokModalValue: {
      alertText: ""
    },
    musicInfoInterval: null,
    likeInfo: {
      like: "",
      unlike: ""
    },
    channelList: [],
    popupStatus: false,
    selectChannelInfo: null,
    favoriteMusicList: [],
    favoriteMyMusicList: [],
    currentChannelInfo: {},
    allMusicList: [],
    tutorialStep1: false,
    tutorialStep2: false,
    eventInterval: null,
    eventCount: 0
  },
  getters: {
    getSpaceInfo (state) {
      return state.spaceInfo;
    },
    getPlayerInfo (state) {
      return state.playerInfo;
    },
    getSpaceName (state) {
      return state.spaceName;
    },
    getSelectWeatherAndTime (state) {
      return state.selectWeatherAndTime;
    },
    getcurrentInfo (state) {
      return state.currentInfo;
    },
    getPreInfo (state) {
      return state.preInfo;
    },
    getDummyList (state) {
      return state.dummyChannelList;
    },
    getWeatherCssId (state) {
      const { times, weather } = state.selectWeatherAndTime;
      let weatherId = 'afternoon';

      if ((String(times) === '2' && weather === '1') || (String(times) === '2' && weather === '4')) {
        weatherId = 'afternoon';
      } else if (String(times) === '2' && weather === '2') {
        weatherId = 'afternoonCloud';
      } else if (String(times) === '2' && weather === '3') {
        weatherId = 'afternoonRain';
      } else if ((String(times) === '3' && weather === '1') || (String(times) === '3' && weather === '4')) {
        weatherId = 'dinner';
      } else if (String(times) === '3' && weather === '2') {
        weatherId = 'dinnerCloud';
      } else if (String(times) === '3' && weather === '3') {
        weatherId = 'dinnerRain';
      } else if ((String(times) === '1' && weather === '1') || (String(times) === '1' && weather === '4')) {
        weatherId = 'morning';
      } else if (String(times) === '1' && weather === '2') {
        weatherId = 'morningCloud';
      } else if (String(times) === '1' && weather === '3') {
        weatherId = 'morningRain';
      } else if ((String(times) === '4' && weather === '1') || (String(times) === '4' && weather === '4')) {
        weatherId = 'night';
      } else if (String(times) === '4' && weather === '2') {
        weatherId = 'nightCloud';
      } else if (String(times) === '4' && weather === '3') {
        weatherId = 'nightRain';
      }

      return weatherId;
    },
    getChannelInfo (state) {
      return state.channelInfo;
    },
    getChannelMusicInfo (state) {
      return state.channelMusicInfo;
    },
    getCurationInfo (state) {
      return state.curationInfo;
    },
    getTimesId (state) {
      let eleId = "afternoon";
      if (state.selectWeatherAndTime.times === "1") {
        eleId = "morning";
      } else if (state.selectWeatherAndTime.times === "2") {
        eleId = "afternoon";
      } else if (state.selectWeatherAndTime.times === "3") {
        eleId = "dinner";
      } else if (state.selectWeatherAndTime.times === "4") {
        eleId = "night";
      }
      return eleId;
    },
    getCurationTemp (state) {
      return state.curationTemp;
    },
    getKisokAlertModal (state) {
      return state.kisokAlertModal;
    },
    getKisokAlertModalValue (state) {
      return state.kisokModalValue;
    },
    getLikeInfo (state) {
      return state.likeInfo;
    },
    getLoadingStatus (state) {
      return state.loadingStatus;
    },
    getChannelStatus (state) {
      return state.channelStatus;
    },
    getChannelList (state) {
      return state.channelList;
    },
    getPopupStatus (state) {
      return state.popupStatus;
    },
    getFavoriteMusicList (state) {
      return state.favoriteMusicList;
    },
    getFavoriteMyMusicList (state) {
      return state.favoriteMyMusicList;
    },
    getSelectChannelInfo (state) {
      return state.selectChannelInfo;
    },
    getCurrentChannelInfo (state) {
      return state.currentChannelInfo;
    },
    getAllMusicList (state) {
      return state.allMusicList;
    },
    getTop100MusicList (state) {
      return state.allMusicList.splice(0, 100);
    },
    geTtutorialStep1 (state) {
      return state.tutorialStep1;
    },
    geTtutorialStep2 (state) {
      return state.tutorialStep2;
    }
  },
  mutations: {
    setSpaceInfo (state, payload) {
      state.spaceInfo = payload;
    },
    setPlayerInfo (state, payload) {
      state.playerInfo = payload;
    },
    setSpaceName (state, payload) {
      state.spaceName = payload;
    },
    setSelectWeatherAndTime (state, payload) {
      state.selectWeatherAndTime = payload;
    },
    setCurrentInfo (state, payload) {
      state.currentInfo = payload;
    },
    setPreInfo (state, payload) {
      state.preInfo = payload;
    },
    setChannelInfo (state, payload) {
      state.channelInfo = payload;
    },
    setChannelMusicInfo (state, payload) {
      state.channelMusicInfo = payload;
    },
    setCurationInfo (state, payload) {
      state.curationInfo = payload;
    },
    setCurationTemp (state, curationTemp) {
      state.curationTemp = curationTemp;
    },
    setAlertModal (state, payload) {
      state.kisokAlertModal = payload;
    },
    setAlertModalValue (state, payload) {
      state.kisokModalValue = payload;
    },
    setMusicInfoInterval (state, payload) {
      state.musicInfoInterval = payload;
    },
    setLikeInfo (state, payload) {
      state.likeInfo = payload;
    },
    setLoadingStatus (state, payload) {
      state.loadingStatus = payload;
    },
    setChannelStatus (state, payload) {
      state.channelStatus = payload;
    },
    setChannelList (state, payload) {
      state.channelList = payload;
    },
    setPopupStatus (state, payload) {
      state.popupStatus = payload;
    },
    setSelectChannelInfo (state, payload) {
      state.selectChannelInfo = payload;
    },
    setFavoriteMusicList (state, payload) {
      state.favoriteMusicList = payload;
    },
    setFavoriteMyMusicList (state, payload) {
      state.favoriteMyMusicList = payload;
    },
    setCurrentChannelInfo (state, payload) {
      state.currentChannelInfo = payload;
    },
    setAllMusicList (state, payload) {
      state.allMusicList = payload;
    },
    setTutorialStep1 (state, payload) {
      state.tutorialStep1 = payload;
    },
    setTutorialStep2 (state, payload) {
      state.tutorialStep2 = payload;
    },
    setEventInterval (state) {
      let count = 0;
      state.eventInterval = setInterval(() => {
        count++;
        // (60초 * 10 = 10분)
        const openTime = 60 * 10;
        // const openTime = 60 * 3;
        if (count === openTime) {
          if (!state.tutorialStep1 && !state.tutorialStep2) {
            state.tutorialStep1 = true;
          }
          clearInterval(state.eventInterval);
        }
      }, 1000);
    },
    setEventCount (state, payload) {
      state.eventCount = payload;
    }
  },
  actions: {
    async updateCuration ({ commit }, curaitioninfo) {
      commit('setLoadingStatus', true);
      const { mood, carMood, domesticRate, foreignRate, genre, period, emotion } = curaitioninfo;
      const result = await updateCurationTemp(mood, carMood, domesticRate, foreignRate, genre, period, emotion);
      const { resultCd: updateResultCd } = result.data;
      if (updateResultCd === '0000') {
        const randomPreferResult = await updateRandomPreferMusic();
        const { resultCd: radomResultCd } = randomPreferResult.data;
        if (radomResultCd === "0000") {
          // 성공
          const confirmCuration = await updateCuration();
          const { resultCd: confirmResultCd } = confirmCuration.data;
          if (confirmResultCd === "0000") {
            const updateCurationNoti = await updateCurationNotice();
            const { resultCd: updateNotiResultCd } = updateCurationNoti.data;
            if (updateNotiResultCd === '0000') {
              const curationInfnResult = await getCurationInfo();
              const { result: curationResult } = curationInfnResult.data;
              commit('setCurationInfo', curationResult);

              return true;
            }
          } else {
            return false;
          }
        } else {
          commit('setLoadingStatus', false);
          return false;
        }
      } else {
        commit('setLoadingStatus', false);
        return false;
      }
    }
  }
};

export { kisokmodule };
