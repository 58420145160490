const cescurationmodule = {
  namespaced: true,
  state: {
    emotion: [
      { id: 'emotion1', value: 1, koText: '차분한', text: "Calm", imgUrl: "/media/img/curation_emotion_1.png" },
      { id: 'emotion2', value: 2, koText: '편안한', text: "Comfortable", imgUrl: "/media/img/curation_emotion_2.png" },
      { id: 'emotion3', value: 3, koText: '활기찬', text: "Active", imgUrl: "/media/img/curation_emotion_3.png" },
      { id: 'emotion4', value: 4, koText: '신나는', text: "Exciting", imgUrl: "/media/img/curation_emotion_4.png" },
      { id: 'emotion5', value: 5, koText: '슬픈', text: "Sad", imgUrl: "/media/img/curation_emotion_5.png" },
      { id: 'emotion6', value: 6, koText: '쓸쓸한', text: "Lonely", imgUrl: "/media/img/curation_emotion_6.png" },
      { id: 'emotion7', value: 7, koText: '독특한', text: "Unique", imgUrl: "/media/img/curation_emotion_7.png" },
      { id: 'emotion8', value: 8, koText: '파워풀한', text: "Powerful", imgUrl: "/media/img/curation_emotion_8.png" },
      { id: 'emotion9', value: 9, koText: '흥분되는', text: "Thrilling", imgUrl: "/media/img/curation_emotion_9.png" }
    ],
    mood: [
      { id: 'mood1', value: 1, koText: '모던', text: 'Modern' },
      { id: 'mood2', value: 2, koText: '럭셔리', text: 'Luxury' },
      { id: 'mood3', value: 3, koText: '빈티지', text: 'Vintage' },
      { id: 'mood4', value: 4, koText: '아기자기', text: 'Cute' },
      { id: 'mood5', value: 5, koText: '트렌디', text: 'Trendy' }
    ],
    target: [
      { id: 'target1', value: 1, koText: '유아/아동', text: 'Kids' },
      { id: 'target2', value: 2, koText: '청소년', text: 'Teen' },
      { id: 'target3', value: 3, koText: '청년', text: 'Youth' },
      { id: 'target4', value: 4, koText: '중장년', text: 'Middle-aged' },
      { id: 'target5', value: 5, koText: '노년', text: 'Elderly' }
    ],
    targetunit: [
      { id: 'targetunit1', value: 1, koText: '혼자', text: 'Alone' },
      { id: 'targetunit2', value: 2, koText: '친구', text: 'Friend' },
      { id: 'targetunit3', value: 3, koText: '연인', text: 'Couple' },
      { id: 'targetunit4', value: 4, koText: '가족', text: 'Family' },
      { id: 'targetunit5', value: 5, koText: '동료', text: 'Colleague' }
    ],
    peroid: [
      { id: 'peroid1', value: 1, koText: '~1980', text: '~1980s' },
      { id: 'peroid2', value: 2, koText: '1990', text: '1990s' },
      { id: 'peroid3', value: 3, koText: '2000', text: '2000s' },
      { id: 'peroid4', value: 4, koText: '2010', text: '2010s' },
      { id: 'peroid5', value: 5, koText: '2020', text: '2020s' }
    ],
    colors: [
      { id: 'color1', value: 1, koText: '흰색', text: 'White' },
      { id: 'color2', value: 2, koText: '검은색', text: 'Black' },
      { id: 'color3', value: 3, koText: '갈색', text: 'Brown' },
      { id: 'color4', value: 4, koText: '금색', text: 'Gold' },
      { id: 'color5', value: 5, koText: '은색', text: 'Silver' },
      { id: 'color6', value: 6, koText: '녹색', text: 'Green' },
      { id: 'color7', value: 7, koText: '남색', text: 'Navy' },
      { id: 'color8', value: 8, koText: '분홍색', text: 'Pink' },
      { id: 'color9', value: 9, koText: '베이지색', text: 'Beige' },
      { id: 'color10', value: 10, koText: '노란색', text: 'Yellow' },
      { id: 'color11', value: 11, koText: '주황색', text: 'Orange' },
      { id: 'color12', value: 12, koText: '빨간색', text: 'Red' },
      { id: 'color13', value: 13, koText: '파란색', text: 'Blue' },
      { id: 'color14', value: 14, koText: '보라색', text: 'Purple' },
      { id: 'color15', value: 15, koText: '무지개색', text: 'Rainbow' },
      { id: 'color16', value: 16, koText: '회색', text: 'Gray' }
    ],
    material: [
      { id: 'material1', value: 1, koText: '나무', text: 'Wood' },
      { id: 'material2', value: 2, koText: '대리석', text: 'Marble' },
      { id: 'material3', value: 3, koText: '유리', text: 'Glass' },
      { id: 'material4', value: 4, koText: '플라스틱', text: 'Plastic' },
      { id: 'material5', value: 5, koText: '가죽', text: 'Leather' },
      { id: 'material6', value: 6, koText: '금속', text: 'Metal' },
      { id: 'material7', value: 7, koText: '등나무', text: 'Rattan' },
      { id: 'material8', value: 8, koText: '석재', text: 'Stone' },
      { id: 'material9', value: 9, koText: '천', text: 'Fabric' },
      { id: 'material10', value: 10, koText: '콘크리트', text: 'Concrete' }
    ],
    genre: [
      { id: "genre1", value: 1, koText: '발라드', text: 'Ballad' },
      { id: "genre2", value: 2, koText: '랩/힙합', text: 'Rap/Hip-hop' },
      { id: "genre3", value: 3, koText: '댄스', text: 'Dance' },
      { id: "genre4", value: 4, koText: 'R&B/Soul', text: 'R&B/Soul' },
      { id: "genre5", value: 5, koText: '재즈', text: 'Jazz' },
      { id: "genre6", value: 6, koText: '일렉트로니카', text: 'Electronica' },
      { id: "genre7", value: 7, koText: '록/메탈', text: 'Rock/Metal' },
      { id: "genre8", value: 9, koText: '포크/블루스/컨트리', text: 'Folk/Country' },
      { id: "genre9", value: 10, koText: '트로트', text: 'Trot' },
      { id: "genre10", value: 111, koText: '클래식', text: 'Classic' },
      { id: "genre11", value: 12, koText: '뉴에이지', text: 'Newage' }
    ]
  },
  getters: {
    getEmotion (state) {
      return state.emotion;
    },
    getColors (state) {
      return state.colors;
    },
    getMaterial (state) {
      return state.material;
    },
    getMood (state) {
      return state.mood;
    },
    getTarget (state) {
      return state.target;
    },
    getPeroid (state) {
      return state.peroid;
    },
    getTargetUnit (state) {
      return state.targetunit;
    },
    getMoodValue: (state) => val => {
      const find = state.mood.find(item => item.koText === val);
      return find;
    },
    getTargetValue: (state) => val => {
      const find = state.target.find(item => item.koText === val);
      return find;
    },
    getTargetUnitValue: (state) => val => {
      const find = state.targetunit.find(item => item.koText === val);
      return find;
    },
    getEmotionValue: (state) => val => {
      const find = state.emotion.find(item => item.koText === val);
      return find;
    },
    getPeroidValue: (state) => val => {
      const find = state.peroid.find(item => item.koText === val);

      return find || {};
    },
    getMoodMappgingEmotion: (state) => val => {
      const find = state.mood.find(item => item.koText === val);

      const { value } = find;
      // 모던
      if (value === 1) {
        return [state.emotion[0], state.emotion[1], state.emotion[2]];
      } else if (value === 2) {
        // 럭셔리
        return [state.emotion[0], state.emotion[1], state.emotion[5]];
      } else if (value === 3) {
        // 빈티지
        return [state.emotion[0], state.emotion[1], state.emotion[6]];
      } else if (value === 4) {
        // 아기자기
        return [state.emotion[0], state.emotion[1], state.emotion[2]];
      } else if (value === 5) {
        // 트렌디
        return [state.emotion[2], state.emotion[3], state.emotion[6]];
      }
      return [];
    },
    getGenre (state) {
      return state.genre.filter(item => item.value !== 10);
    },
    getGenreValue: (state) => val => {
      const find = state.genre.find(item => item.koText === val);
      return find;
    }
  },
  mutations: {},
  actions: {}
};

export { cescurationmodule };
