const cesweathermodule = {
  namespaced: true,
  state: {
    weatherinfo: {
      currentTimes: '낮',
      currentWeather: 1
    },
    geolocationInfo: {
      latitude: 36.2277,
      longitude: -115.264
    },
    openWeatherInfo: {
      icon: "01d",
      id: 800,
      lastUpdt: "2023-11-10 09:25:25",
      name: "Kwangmyŏng",
      status: "Clear",
      sunrise: "2023-11-10 07:05:54",
      sunset: "2023-11-10 17:26:40",
      temp: 275.64,
      weatherCode: "1"
    },
    selectIcon: 'clear_sky',
    loadingBar: false
  },
  getters: {
    getLoadingBar (state) {
      return state.loadingBar;
    },
    getWeatherInfo (state) {
      return state.weatherinfo;
    },
    getWeaterTimeCssId (state) {
      if (state.weatherinfo.currentTimes === '아침') {
        if (state.weatherinfo.currentWeather === 1) {
          return 'morning_time';
        } else if (state.weatherinfo.currentWeather === 2) {
          return "morning_cloud_time";
        } else if (state.weatherinfo.currentWeather === 3 || state.weatherinfo.currentWeather === 4) {
          return "morning_rain_time";
        } else if (state.weatherinfo.currentWeather === 5) {
          return "morning_snow_time";
        }
      } else if (state.weatherinfo.currentTimes === '낮') {
        if (state.weatherinfo.currentWeather === 1) {
          return 'afternoon_time';
        } else if (state.weatherinfo.currentWeather === 2) {
          return "afternoon_cloud_time";
        } else if (state.weatherinfo.currentWeather === 3 || state.weatherinfo.currentWeather === 4) {
          return "afternoon_rain_time";
        } else if (state.weatherinfo.currentWeather === 5) {
          return "afternoon_snow_time";
        }
      } else if (state.weatherinfo.currentTimes === '저녁') {
        if (state.weatherinfo.currentWeather === 1) {
          return 'dinner_time';
        } else if (state.weatherinfo.currentWeather === 2) {
          return "dinner_cloud_time";
        } else if (state.weatherinfo.currentWeather === 3 || state.weatherinfo.currentWeather === 4) {
          return "dinner_rain_time";
        } else if (state.weatherinfo.currentWeather === 5) {
          return "dinner_snow_time";
        }
      } else if (state.weatherinfo.currentTimes === '밤') {
        if (state.weatherinfo.currentWeather === 1) {
          return 'night_time';
        } else if (state.weatherinfo.currentWeather === 2) {
          return "night_cloud_time";
        } else if (state.weatherinfo.currentWeather === 3 || state.weatherinfo.currentWeather === 4) {
          return "night_rain_time";
        } else if (state.weatherinfo.currentWeather === 5) {
          return "night_snow_time";
        }
      }
      return 'afternoon_time';
    },
    getGeolocation (state) {
      return state.geolocationInfo;
    },
    getSelectWeatherIcon (state) {
      return state.selectIcon;
    },
    getOpenWeatherInfo (state) {
      return state.openWeatherInfo;
    },
    getWeatherText (state) {
      if (state.selectIcon === 'clear_sky') {
        return "Clear Sky";
      } else if (state.selectIcon === 'few_clouds') {
        return "Few Clouds";
      } else if (state.selectIcon === 'scattered_clouds') {
        return "Scatterd Clouds";
      } else if (state.selectIcon === 'rain') {
        return "Rain";
      } else if (state.selectIcon === 'thunderstorm') {
        return "Thunderstorm";
      } else if (state.selectIcon === 'snow') {
        return "Snow";
      } else if (state.selectIcon === 'mist') {
        return "Mist";
      }
    }
  },
  mutations: {
    setLoadingBar (state, payload) {
      state.loadingBar = payload;
    },
    setWeatherInfo (state, payload) {
      state.weatherinfo = payload;
    },
    setgeolocationInfo (state, payload) {
      state.geolocationInfo = payload;
    },
    setWeatherIcon (state, payload) {
      state.selectIcon = payload;
    },
    setOpenWeatherInfo (state, payload) {
      state.openWeatherInfo = payload;
    },
    setWeatherCurrentWeather (state, payload) {
      state.weatherinfo.currentWeather = parseInt(payload);
    },
    setWeatherCurrentTime (state, payload) {
      /**
       * 06:01 ~ 09:00 아침
       * 09:01 ~ 18:00 낮
       * 18:01 ~ 21:00 저녁
       * 21:01 ~ 06:00 밤
       */
      state.weatherinfo.currentTimes = payload;
    },
    setIntervalIcon (state, payload) {
      let icon = '';
      const clearsky = ["01d", "01n"];
      const fewCloudes = ["02d", "02n"];
      const scatterdClouds = ["03d", "03n", "04d", "04n"];
      const rain = ["09d", "09n", "10d", "10n"];
      const thunderstorm = ["11d", "11n"];
      const snow = ["13d", "13n"];
      const mist = ["50d", "50n"];

      if (clearsky.includes(payload)) {
        icon = 'clear_sky';
        state.weatherinfo.currentWeather = 1;
      } else if (fewCloudes.includes(payload)) {
        icon = "few_clouds";
        state.weatherinfo.currentWeather = 2;
      } else if (scatterdClouds.includes(payload)) {
        icon = "scattered_clouds";
        state.weatherinfo.currentWeather = 2;
      } else if (rain.includes(payload)) {
        icon = "rain";
        state.weatherinfo.currentWeather = 3;
      } else if (thunderstorm.includes(payload)) {
        icon = "thunderstorm";
        state.weatherinfo.currentWeather = 4;
      } else if (snow.includes(payload)) {
        icon = "snow";
        state.weatherinfo.currentWeather = 5;
      } else if (mist.includes(payload)) {
        icon = "mist";
        state.weatherinfo.currentWeather = 2;
      }
      state.selectIcon = icon;
    }
  },
  actions: {}
};

export { cesweathermodule };
