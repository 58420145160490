/* eslint-disable no-useless-escape */
import { playermodule } from '../store/player/playermodule';
import { curationmodule } from '../store/curation/curationmodule';
import { automaticRecommOfPreferMusic } from '../service/api/profileApi';
import store from '@/store';
import VueCrontab from 'vue-crontab';
import { version } from "../../package.json";

const getCookie = name => {
  const matches = document.cookie.match(
    new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

const curationValidation = (curationSetting = { domesticRate: 0 }, genre = [], peroid = []) => {
  let text = '';
  let isAlert = false;
  // 1980에 노래 없는 장르
  const notExists1980Music = [2, 4, 5, 6, 12];
  peroid = peroid.sort((a, b) => a - b);

  // undefined인 경우 "뉴에이지(12),일렉트로니카(6),재즈(5),랩/힙합(2),R&B/Soul(4)" 으로만 이루어져있음, 값이 있는 경우에는 "뉴에이지(12),일렉트로니카(6),재즈(5),랩/힙합(2),R&B/Soul(4)"값 존재
  const isIncludeAllNotExists1980Music = genre.find(item => {
    if (!notExists1980Music.includes(item)) {
      return true;
    }
    return undefined;
  });

  // 국내곡비율 0%
  if (parseInt(curationSetting.domesticRate) === 0) {
    // 장르 1개인 경우
    if (genre.length === 1) {
      if (genre[0] === 2 && peroid.length === 1 && peroid[0] === 1) {
        text = `해외 랩/힙합 장르만 선호하시는 경우,<br />1980's 이외의 시대도 선택해주세요.`;
        isAlert = true;
      } else if (parseInt(genre[0]) === 6 && peroid.length === 1 && (peroid[0] === 1 || peroid[0] === 2)) {
        // 장르 일렉트로니카 && 시대가 1개이면(1980 || 1990)
        text = `해외 일렉트로니카 장르만 선호하시는 경우,<br />1980s, 1990s 이외의 시대도 선택해주세요.`;
        isAlert = true;
      } else if (parseInt(genre[0]) === 6 && peroid.length === 2 && peroid[0] === 1 && peroid[1] === 2) {
        // 장르 일렉트로니카 && 시대가 2개이면(1980 && 1990)
        text = `해외 일렉트로니카 장르만 선호하시는 경우,<br />1980s, 1990s 이외의 시대도 선택해주세요.`;
        isAlert = true;
      } else if (parseInt(genre[0]) === 12 && peroid.length < 3) {
        // 장르 뉴에이지  && 시대 3개 이하
        text = `해외 뉴에이지 장르만 선호하시는 경우,<br /> 3개 이상의 시대를 선택해주세요.`;
        isAlert = true;
      }
    } else if (genre.length === 2) {
      // 장르가 2개인 경우
      if (genre.includes(2) && genre.includes(6) && peroid.length === 1 && peroid[0] === 1) {
        // 장르 랩/힙합 && 시대 1980 && 장르 일렉트로니카 && 시대 1980
        text = `해외 랩/힙합, 일렉트로니카 장르만 선호하시는 경우,<br /> 1980's 이외의 시대도 선택해주세요.`;
        isAlert = true;
      }
    }
  } else {
    // 뉴에이지(12), 일렉트로니카(6), 재즈(5), 랩 / 힙합(2), R & (B / Soul(4)) 으로 이루어져있는 경우 && 시대가 1980만 되어있는 경우
    if (!isIncludeAllNotExists1980Music && peroid.length === 1 && peroid[0] === 1) {
      const genreText = genre
        .sort((a, b) => a - b)
        .map(item => {
          return curationmodule.getters.getGenreFindValue(curationmodule.state)(item).koText;
        });
      text = `${genreText.join(', ')} 장르만 선호하시는 경우,<br />1980s 이외의 시대도 선택해주세요.`;
      isAlert = true;
    } else if (parseInt(curationSetting.domesticRate) === 80) {
      // 국내곡 비율 80%인 경우
      if (genre.length === 1) {
        // 장르가 1개인 경우
        if (genre[0] === 4 && peroid.length === 1 && peroid[0] === 1) {
          // 장르 R&B Soul && 시대. 1980인 경우
          text = `R&B/Soul 장르만 선호하시는 경우,<br /> 1980s 이외의 시대도 선택해주세요.`;
          isAlert = true;
        } else if (genre[0] === 6 && peroid.length === 1 && (peroid[0] === 1 || peroid[0] === 2)) {
          // 장르가 일렉트로니카인 경우 && 1980 || 1990인 경우
          text = `일렉트로니카 장르만 선호하시는 경우,<br /> 1980s, 1990s 이외의 시대도 선택해주세요`;
          isAlert = true;
        } else if (genre[0] === 6 && peroid.length === 2 && peroid[0] === 1 && peroid[1] === 2) {
          // 장르가 일렉트로니카인 경우 && 1980 && 1990인 경우
          text = `일렉트로니카 장르만 선호하시는 경우,<br /> 1980s, 1990s 이외의 시대도 선택해주세요`;
          isAlert = true;
        } else if (genre[0] === 5 && peroid.length === 1 && peroid[0] === 1) {
          // 장르가 재즈 경우 && 1980 || 1990인 경우
          text = `재즈 장르만 선호하시는 경우,<br /> 1980s 이외의 시대도 선택해주세요.`;
          isAlert = true;
        } else if (genre[0] === 2 && peroid.length === 1 && peroid[0] === 1) {
          // 장르가 랩/힙합 경우 && 1980 || 1990인 경우
          text = `랩/힙합 장르만 선호하시는 경우,<br /> 1980's 이외의 시대도 선택해주세요.`;
          isAlert = true;
        } else if (genre[0] === 12 && peroid.length === 1 && (peroid[0] === 1 || peroid[0] === 2)) {
          // 장르가 랩/힙합 경우 && 1980 || 1990인 경우
          text = `뉴에이지 장르만 선호하시는 경우,<br /> 1980's, 1990's 이외의 시대도 선택해주세요.`;
          isAlert = true;
        } else if (genre[0] === 12 && peroid.length === 2 && peroid[0] === 1 && peroid[1] === 2) {
          // 장르가 랩/힙합 경우 && 1980 || 1990인 경우
          text = `뉴에이지 장르만 선호하시는 경우,<br /> 1980's, 1990's 이외의 시대도 선택해주세요.`;
          isAlert = true;
        }
      } else if (genre.length === 2) {
        // 장르가 2개인 경우
        if ((genre.includes(4) || genre.includes(12)) && peroid.length === 1 && peroid[0] === 1) {
          // 장르 R&B/Soul ||  뉴에이지 && 시대 1980인 경우
          text = `R&B/Soul, 뉴에이지 장르만 선호하시는 경우,<br /> 1980s 이외의 시대도 선택해주세요.`;
          isAlert = true;
        }
      }
    } else {
      // 국내곡 비율 80%이 아닌 경우
      if (genre.length === 1) {
        // 장르가 1개인 경우
        // 선택한 장르가 뉴에이지인 경우 && 1980 || 1990
        if (parseInt(genre[0]) === 12 && peroid.length === 1 && (peroid[0] === 1 || peroid[1] === 2)) {
          text = `뉴에이지 장르만 선호하시는 경우,<br /> 1980's, 1990's 이외의 시대도 선택해주세요.`;
          isAlert = true;
        } else if (parseInt(genre[0]) === 12 && peroid.length === 2 && peroid[0] === 1 && peroid[1] === 2) {
          // 선택한 장르가 뉴에이지인 경우 && 1980 && 1990
          text = `뉴에이지 장르만 선호하시는 경우,<br /> 1980's, 1990's 이외의 시대도 선택해주세요.`;
          isAlert = true;
        } else if (parseInt(genre[0]) === 2 && peroid.length === 1 && peroid[0] === 1) {
          // 선택한 장르가 랩/힙합이고 선택한 시대가 1980s인 경우
          text = `랩/힙합 장르만 선호하시는 경우,<br /> 1980's 이외의 시대도  선택해주세요.`;
          isAlert = true;
        } else if (parseInt(genre[0]) === 6 && peroid.length === 1 && (peroid[0] === 1 || peroid[0] === 2)) {
          //
          text = `일렉트로니카 장르만 선호하시는 경우,<br /> 1980s, 1990s 이외의 시대도 선택해주세요.`;
          isAlert = true;
        } else if (parseInt(genre[0]) === 6 && peroid.length === 2 && peroid[0] === 1 && peroid[1] === 2) {
          //
          text = `일렉트로니카 장르만 선호하시는 경우,<br /> 1980s, 1990s 이외의 시대도 선택해주세요.`;
          isAlert = true;
        }
      }
    }
  }
  return {
    text,
    isAlert
  };
};

const getSpaceName = () => {
  const newArray = [
    playermodule.getters.getMyChannelInfo(playermodule.state),
    ...playermodule.getters.getSimilarChannelList(playermodule.state),
    ...playermodule.getters.getPopularityChannelList(playermodule.state),
    ...playermodule.getters.getDesignatedRecommendedChannels(playermodule.state)
  ];

  if (store.state.channelId !== '') {
    const find = newArray.find(item => item && item.channelId === store.state.channelId);
    if (find) {
      return find.spaceNm;
    } else {
      return store.getters.getSpaceNm;
    }
  }
  return store.getters.getSpaceNm;
};

const getWeatherId = (times = '낮', weather = '1') => {
  let weatherId = 'afternoon';
  if ((times === '낮' && weather === '1') || (times === '낮' && weather === '4')) {
    weatherId = 'afternoon';
  } else if (times === '낮' && weather === '2') {
    weatherId = 'afternoonCloud';
  } else if (times === '낮' && weather === '3') {
    weatherId = 'afternoonRain';
  } else if ((times === '저녁' && weather === '1') || (times === '저녁' && weather === '4')) {
    weatherId = 'dinner';
  } else if (times === '저녁' && weather === '2') {
    weatherId = 'dinnerCloud';
  } else if (times === '저녁' && weather === '3') {
    weatherId = 'dinnerRain';
  } else if ((times === '아침' && weather === '1') || (times === '아침' && weather === '4')) {
    weatherId = 'mornig';
  } else if (times === '아침' && weather === '2') {
    weatherId = 'mornigCloud';
  } else if (times === '아침' && weather === '3') {
    weatherId = 'mornigRain';
  } else if ((times === '밤' && weather === '1') || (times === '밤' && weather === '4')) {
    weatherId = 'night';
  } else if (times === '밤' && weather === '2') {
    weatherId = 'nightCloud';
  } else if (times === '밤' && weather === '3') {
    weatherId = 'nightRain';
  }

  return weatherId;
};

const getWeather = (weatherValue = '1') => {
  let weather = 'sunny';
  if (weatherValue === '1') {
    weather = 'sunny';
  } else if (weatherValue === '2') {
    weather = 'cloud';
  } else if (weatherValue === '3') {
    weather = 'rain';
  } else if (weatherValue === '4') {
    weather = 'snow';
  }
  return weather;
};

const setBg = (imgUrl = '') => {
  const baseUrl = process.env.VUE_APP_BASE_URL;
  document.body.id = '';
  const bgImg = document.querySelector('.bg_img');
  bgImg.src = `${baseUrl}${imgUrl}`;
};

const setBackGroundImg = (imgUrl = '') => {
  document.body.id = '';
  const bgImg = document.querySelector('.bg_img');
  bgImg.src = `${imgUrl}`;
};

const clearBg = () => {
  const bgImg = document.querySelector('.bg_img');
  bgImg.src = '';
};

const checkMobile = () => {
  const varUA = navigator.userAgent.toLowerCase(); // userAgent 값 얻기
  if (varUA.indexOf('android') > -1) {
    // 안드로이드
    return 'android';
  } else if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
    // IOS
    return 'ios';
  } else {
    // 아이폰, 안드로이드 외
    return 'other';
  }
};

const isNotPc = () => {
  const { userAgent, maxTouchPoints } = window.navigator;

  const isMac = /Macintosh/i.test(userAgent);

  if (isMac && maxTouchPoints > 0) return true;

  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobi|mobi/i.test(userAgent);
};

const isGoFavoriteMusic = (genre = []) => {
  // 3가지 이하의 경우
  if (genre.length <= 3) {
    // 재즈(5),클래식(11),뉴에이지(12),으로만 이루어져있는지 확인
    const exceptList = curationmodule.state.genre
      .filter(item => item.value !== 5 && item.value !== 11 && item.value !== 12)
      .map(item => item.value);
    const find = genre.find(item => {
      if (exceptList.includes(item)) return true;
      return false;
    });
    return find !== undefined;
  }

  return true;
};

const checkInstrumental = (original = [], newVal) => {
  const instrumentalList = [5, 11, 12];
  const exceptList = curationmodule.state.genre
    .filter(item => item.value !== 5 && item.value !== 11 && item.value !== 12)
    .map(item => item.value);

  // 비연주곡으로만 이루어져있는 경우
  const isOnlyNoInstrumentalList = !original.find(item => {
    if (instrumentalList.includes(item)) return true;

    return false;
  });

  // 연주곡으로만 이루어져있는 경우
  const isOnlyInstrumentalList = !original.find(item => {
    if (exceptList.includes(item)) return true;
    return false;
  });

  // 연주곡으로 이루어져있는 경우 && 비연주곡 선택한 경우
  if (original.length > 0) {
    if (isOnlyInstrumentalList && exceptList.includes(parseInt(newVal))) {
      return true;
    }

    // 비연주곡으로만 이루어져있는 경우 && 연주곡 선택한 경우
    if (isOnlyNoInstrumentalList && instrumentalList.includes(parseInt(newVal))) {
      return true;
    }
  }

  return false;
};

const retryAutomaticRecomm = async (n = 0, curationId = '', successFn = () => {}, failFn = () => {}) => {
  if (n < 4) {
    try {
      const { data } = await automaticRecommOfPreferMusic(curationId);
      const { resultCd } = data;
      if (resultCd !== '0000') {
        retryAutomaticRecomm(n + 1, curationId, successFn, failFn);
      } else {
        successFn();
      }
    } catch (error) {
      retryAutomaticRecomm(n + 1, curationId, successFn, failFn);
    }
  } else {
    failFn();
  }
};

const isNestedBrandMusic = name => {
  const cronJob = VueCrontab.getJob(name);
  const { hours, minutes } = cronJob.intervals[0];

  // 브랜드음원 리스트
  const brandList = Object.keys(VueCrontab.jobs).filter(item => item.includes('brand'));
  const find = brandList.find(brandItem => {
    const { hours: diffHours, minutes: diffMin } = VueCrontab.getJob(brandItem).intervals[0];
    if (diffHours.includes(hours) && minutes === diffMin) {
      return true;
    }

    return false;
  });

  return !!find;
};

const isNestedAutoPlayMusic = name => {
  const cronJob = VueCrontab.getJob(name);
  const { hours, minutes } = cronJob.intervals[0];

  // 자동재생/중지
  const autoStartPlay = Object.keys(VueCrontab.jobs).filter(item => item.includes('startAutoPlay'));
  const autoEndPlay = Object.keys(VueCrontab.jobs).filter(item => item.includes('endAutoPlay'));

  // 자동재생 존재여부 확인
  const findAutoStartPlay = autoStartPlay.find(startItem => {
    const { hours: diffHours, minutes: diffMin } = VueCrontab.getJob(startItem).intervals[0];
    if (diffHours.includes(hours) && minutes === diffMin) {
      return true;
    }

    return false;
  });

  // 자동중지 존재여부 확인
  const findAutoEndPlay = autoEndPlay.find(endItem => {
    const { hours: diffHours, minutes: diffMin } = VueCrontab.getJob(endItem).intervals[0];

    if (diffHours.includes(hours) && minutes === diffMin) {
      return true;
    }

    return false;
  });

  return !!(findAutoStartPlay || findAutoEndPlay);
};

const versionSetMeta = () => {
  const versionTag = document.createElement("meta");
  document.head.prepend(versionTag);
  versionTag.setAttribute('data-version', version);
};

export {
  getCookie,
  curationValidation,
  getSpaceName,
  getWeatherId,
  getWeather,
  setBg,
  clearBg,
  setBackGroundImg,
  checkMobile,
  isNotPc,
  isGoFavoriteMusic,
  checkInstrumental,
  retryAutomaticRecomm,
  isNestedBrandMusic,
  isNestedAutoPlayMusic,
  versionSetMeta
};
