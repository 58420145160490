import axios from "axios";
import VueCookies from "vue-cookies";
const spaceId = VueCookies.get('spaceId');
const aToken = VueCookies.get("aToken");
const headers = { "X-AUTH-TOKEN": aToken };
const noticeTypeDetail = VueCookies.get('userId');

const updateCurationTemp = async (mood, carMood, domesticRate, foreignRate, genre, period, emotion) => {
  return await axios.post("/api/profile/updateCurationTemp", { spaceId, mood, carMood, domesticRate, foreignRate, genre, period, emotion }, { headers });
};

const updateRandomPreferMusic = async () => {
  return await axios.post("/api/profile/updateRandomPreferMusic", { spaceId }, { headers });
};

const updateCuration = async () => {
  return await axios.post('/api/profile/updateCuration', { spaceId }, { headers });
};

const updateCurationNotice = async () => {
  return await axios
    .post('/api/menu/updateCurationNotice', { spaceId, noticeTypeDetail }, { headers });
};

const getPlayerInfo = async (lon, lat, weather, time) => {
  return await axios
    .post("/api/player/getPlayerInfo", { spaceId, lon, lat, weather, time }, { headers });
};

const getCurationInfo = async () => {
  return await axios
    .post("/api/profile/getCurationInfo", { spaceId }, { headers });
};

export { updateCurationTemp, updateRandomPreferMusic, getPlayerInfo, updateCuration, updateCurationNotice, getCurationInfo };
